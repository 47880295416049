import React, { FC, useCallback, useEffect, useState } from 'react';
import ActionButton from '@yumpingo/yummy-components/components/molecules/ActionButton';
import Input from '@yumpingo/yummy-components/components/molecules/Input';
import Checkbox from '@yumpingo/yummy-components/components/molecules/Checkbox';
import LoadingOverlay from '@yumpingo/yummy-components/components/molecules/LoadingOverlay';
import { BUTTON_HEIGHT } from '../../../../../App.config';
import { BrandContainer } from '../../../../../components/brand-container/BrandContainer';
import { useNextStep } from '../../../hooks/use-next-step';
import { usePreventMultipleClicks } from '../../../hooks/use-prevent-multiple-clicks';
import { Trans, useTranslation } from 'react-i18next';
import { addPostfixToString } from '../../../../../utils/i18n-utils';
import { useDispatch, useSelector } from 'react-redux';
import { selectReviewConfigSubscribe } from '../../../../../redux/reviewConfig/reviewConfigSelectors';
import { selectTokenConfig } from '../../../../../redux/token/tokenSelectors';
import { selectSessionState } from '../../../../../redux/sessionState/sessionSelectors';
import { updateSession } from '../../../../../redux/sessionState/sessionSlice';
import ModalLink from '../../../../../components/modal-frame/ModalLink';
import { DateOfBirthInput, DobInputName } from '../../../components/DateOfBirthInput';
import { useValidateDate } from '../../../hooks/use-validate-date';
import { PunchhSignupRequest } from '../../../../../models/PunchhSignupRequest';
import { eClubSignup, punchhSignup } from '../../../services/integrations';
import { isValidEmail, isValidPhoneNumber } from '../../../../../utils/validation-utils';
import useSimpleToaster, { createToast } from '@yumpingo/yummy-components/hooks/useSimpleToaster';
import { isValidFormPassword } from '../utils';
import { addPunchhEClubSubscriptionAction, addPunchhSubscriptionAction } from '../../../../../redux/middleware/actions';
import { EClubSignupRequest } from '../../../../../models/EClubSignupRequest';

import '../subscribe.scss';

export interface PunchSubscribeValues {
  email?: string;
  password?: string;
  confirmPassword?: string;
  firstName?: string;
  lastName?: string;
  dayDob?: string;
  monthDob?: string;
  yearDob?: string;
  mobilePhone?: string;
  marketingEmailSubscription: boolean;
  marketingPnSubscription: boolean;
  termsAndConditions: boolean;
  sendComplianceSms: boolean;
  optInForEreceipt: boolean;
}

const PunchhSubscribe: FC = () => {
  const config = useSelector(selectTokenConfig);
  const subscribeConfig = useSelector(selectReviewConfigSubscribe);
  const integration = subscribeConfig.integration!;
  const { nextStep, loading } = useNextStep('punchhSubscribe');
  const preventMultipleClicks = usePreventMultipleClicks();
  const { t: translate, i18n } = useTranslation();
  const { addToast } = useSimpleToaster();
  const [loyaltyCheckbox, setLoyaltyCheckbox] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [punchhSignupLoading, setLoading] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [subscribeValues, setSubscribeValues] = useState<PunchSubscribeValues>({
    firstName: '',
    lastName: '',
    email: '',
    mobilePhone: '',
    marketingEmailSubscription: false,
    marketingPnSubscription: false,
    termsAndConditions: true,
    sendComplianceSms: false,
    optInForEreceipt: false,
  });
  const { invalidDate, date: birthday } = useValidateDate(
    subscribeValues.dayDob,
    subscribeValues.monthDob,
    subscribeValues.yearDob
  );
  const [valid, setValid] = useState(false);
  const session = useSelector(selectSessionState);
  const dispatch = useDispatch();

  useEffect(() => {
    // If the integration is punchhLoyalty we should show the registration form
    // If it's punchhEClub we should never show the registration form
    // If it's punchhLoyaltyWithEclub we should show the registration form only if the user checks the box to also register to the eclub
    let showPasswordFields = false;
    switch (integration) {
      case 'punchhEClub':
        showPasswordFields = false;
        break;
      case 'punchhLoyalty':
        showPasswordFields = true;
        break;
      case 'punchhLoyaltyWithEClub':
        showPasswordFields = loyaltyCheckbox;
        break;
    }
    setShowRegistration(showPasswordFields);
  }, [loyaltyCheckbox, integration, setShowRegistration]);

  useEffect(() => {
    if (!subscribeValues.email || !isValidEmail(subscribeValues.email)) {
      setValid(false);
      return;
    }

    // check if the password and confirm password are the same and not null. We also need to check if it's at least 8 characters long
    if (showRegistration && !isValidFormPassword(subscribeValues.password, subscribeValues.confirmPassword)) {
      setValid(false);
      return;
    }

    if (!subscribeValues.firstName || !subscribeValues.lastName) {
      setValid(false);
      return;
    }

    // For punchh the phone number and birthday are not mandatory just check if it's an invalid date if anything present in the 3 inputs
    if (invalidDate) {
      setValid(false);
      return;
    }

    if (!isValidPhoneNumber(`+${subscribeValues.mobilePhone}`)) {
      setValid(false);
      return;
    }

    setValid(true);
  }, [subscribeValues, subscribeConfig, invalidDate, loyaltyCheckbox, showRegistration]);

  const subscribeLoyalty = useCallback(() => {
    const formattedDob = birthday
      ? `${subscribeValues.yearDob}-${subscribeValues.monthDob}-${subscribeValues.dayDob}`
      : '';
    const punchhRequest: PunchhSignupRequest = {
      email: subscribeValues.email!,
      password: subscribeValues.password!,
      firstName: subscribeValues.firstName!,
      lastName: subscribeValues.lastName!,
      marketingEmailSubscription: subscribeValues.marketingEmailSubscription,
      marketingPnSubscription: subscribeValues.marketingPnSubscription,
      termsAndConditions: subscribeValues.termsAndConditions,
      sendComplianceSms: subscribeValues.sendComplianceSms,
      optInForEreceipt: subscribeValues.optInForEreceipt,
      phone: subscribeValues.mobilePhone,
      birthday: formattedDob,
    };

    setLoading(true);
    punchhSignup(punchhRequest)
      .then((response) => {
        const action = updateSession({
          ...session,
          metadata: { ...session!.metadata, punchhUserId: response.punchhUserId },
        });
        dispatch(action);
        dispatch(addPunchhSubscriptionAction({ punchhUserId: response.punchhUserId }));
        nextStep();
      })
      .catch((error) => {
        console.log('error.json', error.json);
        if (error.json) {
          if (error.json.errors) {
            const firstKeyError = Object.keys(error.json.errors)[0];
            addToast(createToast(error.json.errors[firstKeyError][0], 'error', undefined, 3000)); // 3 seconds
          } else {
            addToast(createToast(error.json.message, 'error', undefined, 3000)); // 3 seconds
          }
        } else {
          addToast(createToast('Something went wrong', 'error', undefined, 3000)); // 3 seconds
        }
      })
      .finally(() => setLoading(false));
  }, [birthday, subscribeValues]);

  const subscribeEClub = useCallback(() => {
    const formattedDob = birthday
      ? `${subscribeValues.yearDob}-${subscribeValues.monthDob}-${subscribeValues.dayDob}`
      : '';
    const eClubRequest: EClubSignupRequest = {
      email: subscribeValues.email!,
      firstName: subscribeValues.firstName!,
      lastName: subscribeValues.lastName!,
      marketingEmailSubscription: subscribeValues.marketingEmailSubscription,
      termsAndConditions: subscribeValues.termsAndConditions,
      sendComplianceSms: subscribeValues.sendComplianceSms,
      phone: subscribeValues.mobilePhone!,
      birthday: formattedDob,
    };

    setLoading(true);
    eClubSignup(eClubRequest)
      .then((response) => {
        const action = updateSession({
          ...session,
          metadata: { ...session!.metadata, punchhEClub: true },
        });
        dispatch(action);
        dispatch(addPunchhEClubSubscriptionAction({ punchhEClub: true }));
        nextStep();
      })
      .catch((error) => {
        if (error.json) {
          if (error.json.errors) {
            const firstKeyError = Object.keys(error.json.errors)[0];
            addToast(createToast(error.json.errors[firstKeyError][0], 'error', undefined, 3000)); // 3 seconds
          } else {
            addToast(createToast(error.json.message, 'error', undefined, 3000)); // 3 seconds
          }
        } else {
          addToast(createToast('Something went wrong', 'error', undefined, 3000)); // 3 seconds
        }
      })
      .finally(() => setLoading(false));
  }, [birthday, subscribeValues]);

  const onSubscribe = useCallback(() => {
    if (showRegistration) {
      subscribeLoyalty();
    } else {
      subscribeEClub();
    }
  }, [showRegistration, subscribeEClub, subscribeLoyalty]);

  return loading || punchhSignupLoading ? (
    <LoadingOverlay color="grey4" />
  ) : (
    <BrandContainer headerIsInline>
      {/*TODO SHOW THE CORRECT TILE AND SUBTITLE*/}
      <p className="subscribe-title">{subscribeConfig.title || translate('punchhTitle')}</p>
      <p className="subscribe-subtitle">{subscribeConfig.subtitle || translate('punchhDescription')}</p>
      <div className="subscribe-inputs-container">
        <div>
          <Input
            className="margin-bottom"
            name="firstName"
            value={subscribeValues.firstName}
            onChange={(event) => setSubscribeValues({ ...subscribeValues, firstName: event.currentTarget.value })}
            type="text"
            placeholder={addPostfixToString(translate('firstName'), '*', i18n)}
            required
            data-cy="subscribe-firstName"
          />
          <Input
            className="margin-bottom"
            name="lastName"
            value={subscribeValues.lastName}
            onChange={(event) => setSubscribeValues({ ...subscribeValues, lastName: event.currentTarget.value })}
            type="text"
            placeholder={addPostfixToString(translate('lastName'), '*', i18n)}
            required
            data-cy="subscribe-lastName"
          />
        </div>
        <Input
          className="margin-bottom"
          name="email"
          value={subscribeValues.email}
          onChange={(event) => {
            setSubscribeValues({
              ...subscribeValues,
              email: event.currentTarget.value,
            });
          }}
          type="email"
          placeholder={addPostfixToString(translate('email'), '*', i18n)}
          required
          data-cy="subscribe-email"
        />
        {showRegistration && (
          <>
            <Input
              className="margin-bottom"
              name="password"
              value={subscribeValues.password}
              onChange={(event) => {
                setSubscribeValues({
                  ...subscribeValues,
                  password: event.currentTarget.value,
                });
              }}
              type="password"
              placeholder={addPostfixToString(translate('password'), '*', i18n)}
              required
              data-cy="subscribe-password"
              onFocus={() => setPasswordFocus(true)}
              onBlur={() => setPasswordFocus(false)}
            />
            {passwordFocus && (
              <div className="subscribe-password-disclaimer">
                <p>Password must be at least 8 characters long and contain at least:</p>
                <ul>
                  <li>One number</li>
                  <li>One uppercase letter</li>
                  <li>One lowercase letter</li>
                  <li>One special character</li>
                </ul>
              </div>
            )}

            <Input
              className="margin-bottom"
              name="confirmPassword"
              value={subscribeValues.confirmPassword}
              onChange={(event) => {
                setSubscribeValues({
                  ...subscribeValues,
                  confirmPassword: event.currentTarget.value,
                });
              }}
              type="password"
              placeholder={addPostfixToString(translate('confirmPassword'), '*', i18n)} // TODO MISSING TRANSLATION FOR CONFIRM PASSWORD
              required
              data-cy="subscribe-confirm-password"
            />
          </>
        )}

        <div style={{ display: 'flex' }}>
          <span className="subscribe-plus-span">+</span>
          <div style={{ width: '100%' }}>
            <Input
              className="margin-bottom"
              name="phone"
              value={subscribeValues.mobilePhone}
              onChange={(event) => {
                setSubscribeValues({
                  ...subscribeValues,
                  mobilePhone: event.currentTarget.value,
                });
              }}
              type="tel"
              placeholder={addPostfixToString(translate('phoneNumber'), '*', i18n)}
              data-cy="subscribe-phone"
            />
          </div>
        </div>
        <DateOfBirthInput
          inputValues={subscribeValues}
          onChange={(event: React.ChangeEvent<any>, name: DobInputName) =>
            setSubscribeValues({ ...subscribeValues, [name]: event.currentTarget.value })
          }
        />
      </div>

      {integration === 'punchhLoyaltyWithEClub' && (
        <div className="subscribe-checkbox-container" style={{ marginBottom: 12 }}>
          <Checkbox
            className="subscribe-checkbox"
            name="checkLoyalty"
            label=""
            checked={loyaltyCheckbox}
            onChange={(event) => setLoyaltyCheckbox(event.currentTarget.checked)}
            data-cy="subscribe-punch-email"
            checkedColor="primary"
          />
          <span className="subscribe-checkbox-text">Sign up for loyalty program</span>
        </div>
      )}

      <div className="subscribe-checkbox-container">
        <Checkbox
          className="subscribe-checkbox"
          name="checkEmail"
          label=""
          checked={subscribeValues.marketingEmailSubscription}
          onChange={(event) =>
            setSubscribeValues({
              ...subscribeValues,
              marketingEmailSubscription: event.currentTarget.checked,
            })
          }
          data-cy="subscribe-punch-email"
          checkedColor="primary"
        />
        <span className="subscribe-checkbox-text">
          <Trans t={translate} i18nKey="agreeToMarketingEmails">
            I agree to receive marketing emails from <span className="subscribe-brand-span">{{ name: 'Punchh' }}</span>
          </Trans>
        </span>
      </div>

      <div className="subscribe-checkbox-container" style={{ marginTop: 12 }}>
        <Checkbox
          className="subscribe-checkbox"
          name="checkSMSmarketing"
          label=""
          checked={subscribeValues.sendComplianceSms}
          onChange={(event) =>
            setSubscribeValues({
              ...subscribeValues,
              sendComplianceSms: event.currentTarget.checked,
            })
          }
          data-cy="subscribe-check-marketing-sms"
          checkedColor="primary"
        />
        <span className="subscribe-checkbox-text">
          {/*<Trans i18nKey="agreeToMarketingEmails">*/}
          {/*  I agree to receive marketing emails from <span className="subscribe-brand-span">{{ name: 'Yumpingo' }}</span>*/}
          {/*</Trans>*/}
          {/*  TODO ADD TRANSLATION AND CHECK COPY */}I agree to receive marketing SMS from{' '}
          <span className="subscribe-brand-span">Punchh</span>
        </span>
      </div>

      {/* If we show the registration field it means we can also check if the user wants to opt-in for e-receipt*/}
      {showRegistration && (
        <div className="subscribe-checkbox-container" style={{ marginTop: 12 }}>
          <Checkbox
            className="subscribe-checkbox"
            name="checkEReceipt"
            label=""
            checked={subscribeValues.optInForEreceipt}
            onChange={(event) =>
              setSubscribeValues({
                ...subscribeValues,
                optInForEreceipt: event.currentTarget.checked,
              })
            }
            data-cy="subscribe-check-ereceipt"
            checkedColor="primary"
          />
          <span className="subscribe-checkbox-text">
            Opt in to receive e-receipt from <span className="subscribe-brand-span">Punchh</span>
          </span>
        </div>
      )}

      <div className="subscribe-footer-container">
        <p className="subscribe-terms-title">{translate('t&cs')}</p>
        {/*TODO SETUP THE CORRECT PRIVACY POLICY FOR PUNCHH */}
        <p className="subscribe-terms-text">
          Bla bla bla bla bla bla bla bla blaaaaaa blaaa blaaa blaaa blaaa blaaa blaaa blaaa blaaa blaaa blaaa
          blaaa.&nbsp;
          <span className="subscribe-terms-link">
            <ModalLink title={`Punchh ${translate('privacyPolicy')}.`} url={config?.ppUrl || 'http://google.com'} />
          </span>
          {/*{config?.ppUrl && (*/}
          {/*  <>*/}
          {/*    &nbsp;*/}
          {/*    <span className="subscribe-terms-link">*/}
          {/*      <ModalLink title={`${config?.name} ${translate('privacyPolicy')}`} url={config.ppUrl} />*/}
          {/*    </span>*/}
          {/*  </>*/}
          {/*)}*/}
        </p>
        <div className="subscribe-button-container">
          <ActionButton
            width="small"
            height={BUTTON_HEIGHT}
            backgroundColor="grey3"
            onClick={preventMultipleClicks(nextStep)}
            data-cy="subscribe-skip"
          >
            {translate('skip')}
          </ActionButton>

          <ActionButton
            className="subscribe-primary-button"
            width="large"
            height={BUTTON_HEIGHT}
            backgroundColor="primary"
            disabled={!valid}
            onClick={onSubscribe}
            data-cy="subscribe-submit"
          >
            {translate('subscribe')}
          </ActionButton>
        </div>
      </div>
    </BrandContainer>
  );
};

export default React.memo(PunchhSubscribe);

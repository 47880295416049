import { apiPost } from '../../../utils/api-request';
import { environment } from '../../../environment';
import { retrieveToken } from '../../../utils/token-utils';
import { PunchhSignupRequest } from '../../../models/PunchhSignupRequest';
import { PunchhSignupResponse } from '../../../models/PunchhSignupResponse';
import { EClubSignupRequest } from '../../../models/EClubSignupRequest';

export async function punchhSignup(signupRequest: PunchhSignupRequest): Promise<PunchhSignupResponse> {
  const apiToken = retrieveToken();

  return apiPost(`${environment.guestApiUrl}/signup/punchh`, apiToken!, signupRequest);
}

export async function eClubSignup(signupRequest: EClubSignupRequest): Promise<void> {
  const apiToken = retrieveToken();

  return apiPost(`${environment.guestApiUrl}/signup/punchh-eclub`, apiToken!, signupRequest);
}

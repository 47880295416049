import React, { FC, useCallback, useState } from 'react';
import ModalFrame from './ModalFrame';

interface Props {
  url: string;
  title: string;
  'data-cy'?: string;
}

const ModalLink: FC<Props> = ({ url, title, 'data-cy': dataCy }) => {
  const [isOpen, setOpen] = useState(false);
  const toggle = useCallback(() => setOpen(!isOpen), [isOpen, setOpen]);

  return (
    <>
      <span style={{ cursor: 'pointer' }} onClick={toggle} data-cy={dataCy}>
        {title}
      </span>
      <ModalFrame url={url} title={title} isOpen={isOpen} onClose={toggle} data-cy={dataCy && `${dataCy}-iframe`} />
    </>
  );
};

export default ModalLink;

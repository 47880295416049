import debounce from 'lodash.debounce';
import { DependencyList, useCallback, useRef } from 'react';

const useDebouncedCallback = (callback: Function, delay: number, deps?: DependencyList) => {
  const callbackRef = useRef<Function>();
  callbackRef.current = callback;
  return useCallback(
    debounce((...args) => callbackRef?.current?.(...args), delay),
    [...(deps || [])]
  );
};

export default useDebouncedCallback;

import { Middleware, createAction } from '@reduxjs/toolkit';
import {
  UpdateType,
  UpdateEntity,
  ReviewUpdatedEvent,
  createId,
  GuestJourneyEventType,
} from '../../utils/guest-journey';
import {
  beginSessionAction,
  removeReviewAction,
  removeAllMissingReviewsAction,
  addReviewAction,
  filterOutReviewsAction,
  addAnswerAction,
  removeAnswerAction,
  addPunchhSubscriptionAction,
  addPunchhEClubSubscriptionAction,
} from './actions';
import { apiPost } from '../../utils/api-request';
import { environment } from '../../environment';
import { decodeTokenHandled } from '../../utils/token-utils';

export const guestJourneyMiddleware: Middleware = (store) => (next) => (action) => {
  const trackReviewUpdated = async (data: ReviewUpdatedEvent['data']) => {
    const tokenState = decodeTokenHandled(store.getState().token);
    // If there is no token state, we can't track the event
    if (!tokenState) {
      return;
    }
    const eventData = {
      data: {
        id: createId(data, tokenState.gjid),
        ...data,
      },
      brandId: tokenState.buid,
      venueId: tokenState.vuid,
      guestJourneyId: tokenState.gjid,

      type: GuestJourneyEventType.ReviewUpdated,
      eventTimestamp: new Date().toISOString(),
    };
    try {
      await apiPost<void>(`${environment.guestApiUrl}/guest/events`, undefined, [eventData]);
    } catch (e) {
      // dont throw error if guest journey event fails
      console.error('Error sending guest journey event', e);
    }
  };

  switch (action.type) {
    case beginSessionAction.type: {
      const data = action.payload;
      if (data) {
        trackReviewUpdated({
          type: UpdateType.Add,
          entity: UpdateEntity.Metadata,
          data: data,
        });
      }
      break;
    }
    case removeReviewAction.type: {
      const data = action.payload;
      if (data) {
        trackReviewUpdated({
          entity: UpdateEntity.DishReview,
          type: UpdateType.Remove,
          data: data,
        });
      }
      break;
    }
    case removeAllMissingReviewsAction.type: {
      const data = action.payload;
      if (data) {
        trackReviewUpdated({
          entity: UpdateEntity.DishReview,
          type: UpdateType.Remove,
          data: data,
        });
      }
      break;
    }
    case addReviewAction.type: {
      const data = action.payload;
      if (data) {
        trackReviewUpdated({
          entity: UpdateEntity.DishReview,
          type: UpdateType.Add,
          data: data,
        });
      }
      break;
    }
    case filterOutReviewsAction.type: {
      const data = action.payload;
      if (data) {
        trackReviewUpdated({
          entity: UpdateEntity.DishReview,
          type: UpdateType.Remove,
          data: data,
        });
      }
      break;
    }
    case addAnswerAction.type: {
      const data = action.payload;
      if (data) {
        trackReviewUpdated({
          type: UpdateType.Add,
          entity: UpdateEntity.Question,
          data: data,
        });
      }
      break;
    }
    case removeAnswerAction.type: {
      const data = action.payload;
      if (data) {
        trackReviewUpdated({
          type: UpdateType.Remove,
          entity: UpdateEntity.Question,
          data: data,
        });
      }
      break;
    }
    case addPunchhSubscriptionAction.type: {
      const data = action.payload;
      trackReviewUpdated({
        type: UpdateType.Add,
        entity: UpdateEntity.Metadata,
        data: data,
      });
      break;
    }
    case addPunchhEClubSubscriptionAction.type: {
      const data = action.payload;
      trackReviewUpdated({
        type: UpdateType.Add,
        entity: UpdateEntity.Metadata,
        data: data,
      });
      break;
    }
    default:
      break;
  }
  return next(action);
};

function track(
  ReviewUpdated: any,
  eventData: {
    data: { type: UpdateType; entity: UpdateEntity; id: any; data: any };
    brandId: any;
    venueId: any;
    guestJourneyId: any;
    type: Event | undefined;
    eventTimestamp: string;
  }
) {
  throw new Error('Function not implemented.');
}

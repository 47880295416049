import { createSlice } from '@reduxjs/toolkit';
import { saveToken } from '../../utils/token-utils';

type TokenState = string | null;

const tokenSlice = createSlice({
  name: 'token',
  initialState: null as TokenState,
  reducers: {
    updateToken: (_state, action) => {
      if (!action.payload) {
        const STORAGE_KEY = btoa('Global/ApiToken');
        localStorage.removeItem(STORAGE_KEY);
        return null;
      }
      saveToken(action.payload);
      return action.payload;
    },
  },
});

export const { updateToken } = tokenSlice.actions;

export default tokenSlice.reducer;

import { createSelector } from 'reselect';
import { RootState } from '../store';

const globalRootState = (state: RootState) => state.global;

// TODO: This LoadFromStorage state is set but never used. remote it?
export const selectLoadedFromStorageState = createSelector(globalRootState, (global) => {
  return global.loadedFromStorage;
});

// TODO: This error state is never set as it was used in the central/checkin process which was removed. remote it?
export const selectErrorState = createSelector(globalRootState, (global) => {
  return global.error;
});

import { useSelector } from 'react-redux';
import { selectReviewConfigData } from '../redux/reviewConfig/reviewConfigSelectors';
import { getAllReviewsForItem, isItemReviewComplete } from '../models/ItemReview';
import { selectSessionState } from '../redux/sessionState/sessionSelectors';

export const useReviewConfigFood = () => {
  const config = useSelector(selectReviewConfigData);
  const session = useSelector(selectSessionState);

  if (!config) {
    return {
      enableMissingDishes: false,
      menu: [],
    };
  }

  const food = config.food;
  const flow = config.flow;

  const reviews = session && session !== null ? session.reviews : undefined;

  return {
    ...food,
    menu: food?.menu.map((item) => ({
      ...item,
      reviewsCount: getAllReviewsForItem(flow, item, reviews).filter((r) => !r.missing).length,
      missingCount: getAllReviewsForItem(flow, item, reviews || []).filter((r) => r.missing).length,
      completed: isItemReviewComplete(flow, item, reviews),
    })),
  };
};

import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { YummyGlobalStyles, useTheme } from '@yumpingo/yummy-components';
import { fetchFonts } from './utils';

const Styles: FC<{ children?: ReactNode }> = ({ children }) => {
  const theme = useTheme();

  useEffect(() => {
    if (theme?.fonts?.main) {
      fetchFonts(theme?.fonts?.main);
    }
    if (theme?.fonts?.title) {
      fetchFonts(theme.fonts.title);
    }
  }, [theme]);

  // we only need to set the font and component styles here
  // the YummyGlobalStyles component handles the color palette variables
  const additionalStyles = useMemo(() => {
    let styles = '';
    if (theme.components?.cta?.borderRadius) {
      styles += `:root { --action-button-border-radius: ${theme.components.cta.borderRadius}; }\n`;
    }
    if (theme.fonts?.main) {
      styles += `body { font-family: '${theme.fonts.main.name}'; }`;
    }
    if (theme.fonts?.title) {
      styles += `.yummy-custom-title { font-family: '${theme.fonts.title.name}';}`;
    }

    return styles;
  }, [theme]);

  return <YummyGlobalStyles additionalStyles={additionalStyles}>{children}</YummyGlobalStyles>;
};

export default Styles;

import { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { selectTokenCustomDismenions } from '../../../redux/token/tokenSelectors';
import { useSelector } from 'react-redux';

interface Props {
  trackingCode: string;
}

export const GoogleAnalytics: FC<Props> = ({ trackingCode }) => {
  const customDimensions = useSelector(selectTokenCustomDismenions);
  const location = useLocation();
  const [isFirstPageView, setIsFirstPageView] = useState(true);

  useEffect(() => {
    if (trackingCode) {
      ReactGA.initialize(trackingCode);
    }
  }, [trackingCode]);

  useEffect(() => {
    if (isFirstPageView) {
      setIsFirstPageView(false);
      return;
    }

    if (trackingCode && !isFirstPageView) {
      const dimensions =
        customDimensions?.reduce((agg, value, i) => ({ ...agg, ['ua_dimension_' + (i + 1)]: value }), {}) || {};
      // the only way to set custom dimensions is to set them on every event
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search, ...dimensions });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingCode, location.pathname, location.search]);

  return null;
};

import { AsyncState } from './types';
import { useDispatch as useReduxDispatch } from 'react-redux';
import { AppDispatch } from './store';

export const useAppDispatch = () => useReduxDispatch<AppDispatch>();

/**
 * Creates the initial state for standard async states.
 *
 * Basic Usage:
 *
 * const initialState: UserProfileState = createInitialAsyncState<UserProfile>();
 */
export const createInitialAsyncState = <T = any>(): AsyncState<T> => ({
  isFetching: false,
  didInvalidate: false,
});

export const THREE_HOURS = 3 * 60 * 60 * 1000;

import { createSelector } from 'reselect';
import { RootState } from '../store';

const reviewConfigRootState = (root: RootState) => root.reviewConfig;

export const selectReviewConfigData = createSelector(reviewConfigRootState, ({ data }) => data);
export const selectReviewConfigIsFetching = createSelector(reviewConfigRootState, ({ isFetching }) => isFetching);
export const selectReviewConfigError = createSelector(reviewConfigRootState, ({ didInvalidate, error }) =>
  didInvalidate ? error : undefined
);
export const selectReviewConfigBackground = createSelector(reviewConfigRootState, ({ data }) => data?.default);

export const selectWholeReviewConfigState = createSelector(
  [selectReviewConfigIsFetching, selectReviewConfigError, selectReviewConfigData],
  (isFetching, error, data) => ({
    isFetching,
    error,
    data,
  })
);

export const selectReviewConfigService = createSelector(selectReviewConfigData, (config) => {
  if (!config) {
    return {
      questions: [],
      requireFixed: false,
      showFirst: false,
      slots: 0,
    };
  }
  return config.service;
});

export const selectReviewConfigLanguages = createSelector(
  selectReviewConfigData,
  (config) => config?.languages || undefined
);

export const selectReviewConfigSubscribeIntegration = createSelector(selectReviewConfigData, (config) => {
  return config?.subscribe.integration;
});

export const selectReviewConfigSubscribe = createSelector(selectReviewConfigData, (config) => {
  if (!config) {
    return {
      captureEmail: false,
      captureName: false,
      capturePhoneNumber: false,
      captureBirthday: false,
      consentUnticked: false,
      hideYumpingoConsent: false,
    };
  }
  return config.subscribe;
});

export const selectReviewConfigCurrencyAsDateFormat = createSelector(selectReviewConfigData, (config) => {
  // uses the currency as the date format
  if (!config) {
    return {
      dateFormat: 'GBP',
    };
  }
  return { dateFormat: config.currency };
});

export const selectReviewConfigComplaint = createSelector(selectReviewConfigData, (config) => {
  if (!config) {
    return {
      captureEmail: false,
      captureName: false,
      title: '',
      subtitle: '',
      isComplaintTextMandatory: false,
    };
  }
  return config.complaint;
});

export const selectReviewConfigTheme = createSelector(selectReviewConfigData, (config) => {
  return config?.theme || undefined;
});

/**
 * Return food config, include counts for reviews and missing items on the menu items,
 * and a "completed" flag for when an item has been reviewed/ marked missing to the quantity that was ordered
 */
// WE ARE NOT USING THIS WHILE sessionState IS STILL IN RECOIL
// Use useReviewConfigFood hook instead
// export const selectReviewConfigFood = createSelector(selectReviewConfigData, (config) => {
//   if (!config) {
//     return {
//       enableMissingDishes: false,
//       menu: [],
//     };
//   }

//   const food = config.food;
//   const flow = config.flow;
//   // FIXME: Once the sessionState is moved over from recoil to redux, we should rewrite this.
//   const sessionJson = localStorage.getItem(btoa('Review/Session'));
//   const reviews = sessionJson && sessionJson !== 'null' ? JSON.parse(sessionJson).reviews : undefined;

//   return {
//     ...food,
//     menu: food?.menu.map((item) => ({
//       ...item,
//       reviewsCount: getAllReviewsForItem(flow, item, reviews).filter((r) => !r.missing).length,
//       missingCount: getAllReviewsForItem(flow, item, reviews || []).filter((r) => r.missing).length,
//       completed: isItemReviewComplete(flow, item, reviews),
//     })),
//   };
// });

export const selectReviewConfigFlow = createSelector(selectReviewConfigData, (config) => {
  if (!config) {
    return 'standard';
  }

  return config.flow;
});

export const selectReviewConfigFinal = createSelector(selectReviewConfigData, (config) => {
  if (!config) {
    return {};
  }
  return config.final;
});

// WE ARE NOT USING THIS WHILE sessionState IS STILL IN RECOIL
// export const selectReviewConfigCanReportMissingDishes = createSelector(selectReviewConfigFood, (foodConfig) => {
//   if (!foodConfig) {
//     return false;
//   }
//   return foodConfig.enableMissingDishes;
// });

export const selectReviewConfigSplash = createSelector(selectReviewConfigData, (config) => {
  if (!config || !config.splash || typeof config.splash !== 'object') {
    return {};
  }
  return config.splash;
});

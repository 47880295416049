import { RootState } from '../store';
import { WithExpiry } from '../../models/WithExpiry';
import { EXPERIENCE_DRIVERS_STORAGE_KEY, ExperienceDriversState } from './experienceDriversStateSlice';
import { createSelector } from 'reselect';
import { TopicsWithOtherComments, SessionIssuesByTopic } from '../../models/ServiceConfig';

export type ExperienceDriversWithExpiry = WithExpiry<ExperienceDriversState>;

const experienceDriversRootState = (state: RootState) => state.experienceDrivers;

export const selectExperienceDriversState = createSelector(experienceDriversRootState, (experienceDrivers) => {
  // if we have it in redux memory, return it
  if (experienceDrivers) return experienceDrivers;

  // otherwise, check local storage
  const experienceDriversWithExpiry: ExperienceDriversWithExpiry = JSON.parse(
    localStorage.getItem(EXPERIENCE_DRIVERS_STORAGE_KEY) as string
  );

  if (experienceDriversWithExpiry && Date.now() <= experienceDriversWithExpiry.expiresAt) {
    // if not expired, return it
    return experienceDriversWithExpiry.data;
  } else {
    // otherwise, remove it from local storage and return null
    localStorage.removeItem(EXPERIENCE_DRIVERS_STORAGE_KEY);
    return null;
  }
});

export const selectTopicIssuesState = createSelector(selectExperienceDriversState, (experienceDrivers) => {
  return experienceDrivers?.topicIssues || [];
});

export const selectTopicCommentsState = createSelector(experienceDriversRootState, (experienceDrivers) => {
  return experienceDrivers?.topicOtherComments || [];
});

export const selectSessionExperienceDriversSelector = createSelector(
  selectTopicIssuesState,
  selectTopicCommentsState,
  (topicsWithIssues, topicsWithComments) => {
    const topicComments = topicsWithComments.filter((t) => !!t.comment);

    let issuesByTopic: SessionIssuesByTopic = {};
    topicComments.concat(topicsWithIssues).forEach(({ id, name, issues, comment }: TopicsWithOtherComments) => {
      const issueIds = issues.map((issue) => issue.id);
      issuesByTopic[id] = {
        topic: name,
        ids: issuesByTopic[id] ? issuesByTopic[id].ids.concat(issueIds) : issueIds,
        other: comment || issuesByTopic[id]?.other,
      };
    });
    return Object.values(issuesByTopic);
  }
);

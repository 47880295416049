import { createSlice } from '@reduxjs/toolkit';
import { Session } from '../../models/Session';
import { THREE_HOURS } from '../utils';
import { SessionWithExpiry } from './sessionSelectors';

const STORAGE_KEY = btoa('Review/Session');

const sessionSlice = createSlice({
  name: 'session',
  initialState: null as Session | null,
  reducers: {
    updateSession: (state, action) => {
      if (!action.payload) {
        localStorage.removeItem(STORAGE_KEY);
        return { ...state, ...action.payload };
      }
      const expirationTime = Date.now() + THREE_HOURS;
      const sessionWithExpiry: SessionWithExpiry = {
        data: action.payload,
        expiresAt: expirationTime,
      };
      localStorage.setItem(STORAGE_KEY, JSON.stringify(sessionWithExpiry));
      return { ...state, ...action.payload };
    },
  },
});

export const { updateSession } = sessionSlice.actions;

export default sessionSlice.reducer;

import LoadingOverlay from '@yumpingo/yummy-components/components/molecules/LoadingOverlay';
import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { executeCallback } from '../../../../../models/Callback';
import { ErrorDisplay } from '../../../../../pages/error/ErrorDisplay';
import { REVIEW_ROUTES } from './ReviewRoutes.config';
import { fetchReviewConfig } from '../../../../../redux/reviewConfig/reviewConfigSlice';
import { useAppDispatch } from '../../../../../redux/utils';
import {
  selectReviewConfigService,
  selectWholeReviewConfigState,
} from '../../../../../redux/reviewConfig/reviewConfigSelectors';
import { useSelector } from 'react-redux';
import { useReviewConfigFood } from '../../../../../hooks/useReviewConfigFood';
import { selectTokenOnError } from '../../../../../redux/token/tokenSelectors';
import { selectSessionReviewed, selectSessionState } from '../../../../../redux/sessionState/sessionSelectors';
import { useCorrectLanguage } from '../../../../../hooks/useCorrectLanguage';
import i18n from '../../../../../i18n';
import { QuestionSettings } from '../../../../../models/ReviewQuestion';
import { preloadImages } from '../../../../../utils/load-images';
import PunchhSubscribe from '../../../../../pages/review/pages/subscribe/punchh/PunchhSubscribe';
import lazyWithRetry from '../../../../../utils/lazy-import-retry';

const Final = lazyWithRetry(() => import('../../../../../pages/review/pages/final/Final'), 'Final');
const Subscribe = lazyWithRetry(
  () => import('../../../../../pages/review/pages/subscribe/main/Subscribe'),
  'Subscribe'
);
const Review = lazyWithRetry(() => import('../../../../../pages/review/Review'), 'Review');
const Splash = lazyWithRetry(() => import('../../../../../pages/review/pages/splash/Splash'), 'Splash');
const Food = lazyWithRetry(() => import('../../../../../pages/review/pages/food/Food'), 'Food');
const FoodReviewList = lazyWithRetry(
  () => import('../../../../../pages/review/pages/food/pages/food-review-list/FoodReviewList'),
  'FoodReviewList'
);
const Complaint = lazyWithRetry(() => import('../../../../../pages/review/pages/complaint/Complaint'), 'Complaint');
const Service = lazyWithRetry(() => import('../../../../../pages/review/pages/service/Service'), 'Service');
const ItemReviewPage = lazyWithRetry(
  () => import('../../../../../pages/review/pages/food/pages/item-review/ItemReview'),
  'ItemReviewPage'
);

export const missingItemsPath = 'missing-items';

const ReviewRoutes: FC = () => {
  const reviewed = useSelector(selectSessionReviewed);
  const session = useSelector(selectSessionState);
  const dispatch = useAppDispatch();
  const reviewStepFromStorage = localStorage.getItem('review_step');
  const { data: reviewConfig, isFetching, error } = useSelector(selectWholeReviewConfigState);
  const { options, handleLanguageChange } = useCorrectLanguage(
    () => dispatch(fetchReviewConfig()),
    reviewConfig?.languages
  );

  // Preload any alternate question icons
  useEffect(() => {
    const settings = reviewConfig?.service?.questions
      ?.map((question) => question.settings)
      ?.filter(Boolean) as QuestionSettings[];
    if (settings) {
      settings.forEach((setting) => {
        const { fiveScaleIconsSelected, fiveScaleIconsUnselected, fiveStarIcons } = setting;
        preloadImages(fiveScaleIconsSelected);
        preloadImages(fiveScaleIconsUnselected);
        preloadImages(fiveStarIcons);
      });
    }
  }, [reviewConfig]);

  const onError = useSelector(selectTokenOnError);
  const history = useHistory();
  const { t } = useTranslation();

  const foodConfig = useReviewConfigFood();
  const enableMissingDishes = foodConfig.enableMissingDishes || false;

  const reviewStep = useMemo(() => history.location.pathname + history.location.search, [history.location]);
  const serviceConfig = useSelector(selectReviewConfigService);

  useEffect(() => {
    // if the session in progress we already have the review config, no need to fetch it again
    if (reviewStepFromStorage === null || serviceConfig.questions.length === 0) {
      dispatch(fetchReviewConfig());
    }
  }, [dispatch, reviewStepFromStorage, serviceConfig.questions.length]);

  /**
   * If the configuration does not include the current browser language,
   * then set the language to the first item in the "config.languages" array.
   * The "options" are set as this hook dependency as they are in fact derived from "config.languages"
   */
  useEffect(() => {
    if (options && options.length > 0 && !options.find(({ id }) => id === i18n.language)) {
      const defaultLingo = options[0] && options[0].id ? options[0].id : 'en';

      handleLanguageChange(defaultLingo);
    }
  }, [options, handleLanguageChange]);

  useEffect(() => {
    localStorage.setItem('review_step', reviewStep);
  }, [reviewStep]);

  if (error) {
    if (error.code === 'SESSION_PUBLISHED') {
      return <ErrorDisplay icon="logo" title={t('thankYouForYourReview')} description={t('yourReviewHasBeenPosted')} />;
    } else if (!executeCallback(history, onError).stopExecution) {
      return (
        <ErrorDisplay
          description={error.message}
          primaryAction={() => dispatch(fetchReviewConfig())}
          secondaryAction={() => history.push('/')}
        />
      );
    }
  }

  if (reviewed) {
    return (
      <Switch>
        <Route path={REVIEW_ROUTES.final} exact component={Final} />
        <Redirect to={REVIEW_ROUTES.final} />
      </Switch>
    );
  } else if (session?.status === 'pending') {
    const punchhSubscribe = (
      <>
        <Route path={REVIEW_ROUTES.punchhSubscribe} exact component={PunchhSubscribe} />
        <Redirect to={REVIEW_ROUTES.punchhSubscribe} />
      </>
    );

    const standardRoutes = (
      <>
        <Route path={REVIEW_ROUTES.subscribe} exact component={Subscribe} />
        <Redirect to={REVIEW_ROUTES.subscribe} />
      </>
    );

    switch (reviewConfig?.subscribe.integration) {
      case 'punchhLoyalty':
      case 'punchhEClub':
      case 'punchhLoyaltyWithEClub':
        return <Switch>{punchhSubscribe}</Switch>;
      default:
        return <Switch>{standardRoutes}</Switch>;
    }
  }

  if (isFetching) {
    return <LoadingOverlay color="grey4" />;
  }

  return (
    <Switch>
      <Route path={REVIEW_ROUTES.review} exact component={Review} />
      <Route path={REVIEW_ROUTES.splash} exact component={Splash} />
      <Route path={REVIEW_ROUTES.food} exact component={Food} />
      <Route path={`${REVIEW_ROUTES.food}/items`} exact component={FoodReviewList} />
      {enableMissingDishes && (
        <Route
          path={`${REVIEW_ROUTES.food}/${missingItemsPath}`}
          exact={true}
          render={() => <FoodReviewList reportMissingItems={true} />}
        />
      )}
      <Route
        path={`${REVIEW_ROUTES.food}/items/:itemId`}
        exact
        render={(props) => <ItemReviewPage itemId={props.match.params.itemId} />}
      />
      <Route path={REVIEW_ROUTES.subscribe} exact component={Subscribe} />
      <Route path={REVIEW_ROUTES.punchhSubscribe} exact component={PunchhSubscribe} />
      <Route path={REVIEW_ROUTES.complaint} exact component={Complaint} />
      <Route path={REVIEW_ROUTES.service} exact component={Service} />
      <Route path={REVIEW_ROUTES.final} exact component={Final} />
      <Route path={REVIEW_ROUTES.sending} exact component={LoadingOverlay} />
      <Redirect to={REVIEW_ROUTES.review} />
    </Switch>
  );
};

export default React.memo(ReviewRoutes);

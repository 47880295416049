import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSessionState } from '../../redux/sessionState/sessionSelectors';
import { useNextStep } from '../../pages/review/hooks/use-next-step';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';
import Modal from '@yumpingo/yummy-components/components/atoms/Modal';
import ActionButton from '@yumpingo/yummy-components/components/molecules/ActionButton';
import { useTranslation } from 'react-i18next';
import { selectTokenConfig } from '../../redux/token/tokenSelectors';

import './SessionTimeout.style.scss';

const SessionTimeout: FC = ({ children }) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const timeoutSessionDuration = 1000 * 150 * 2.5; // 2.5 minutes to align with devices
  const timeoutDialogDuration = 1000 * 60; // 1 minute. If there is not interaction for this time, the dialog will be shown
  const timeOutSession = useRef<NodeJS.Timeout | undefined>();
  const timeOutDialog = useRef<NodeJS.Timeout | undefined>();
  const session = useSelector(selectSessionState);
  const tokenConfig = useSelector(selectTokenConfig);
  // simulate the last step of the review to keep everything centralised
  const { nextStep } = useNextStep('subscribe');

  const onTimeOutSession = useCallback(() => {
    setIsDialogOpen(false);
    nextStep();
  }, [nextStep]);

  const onTimeOutDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  useEffect(() => {
    // clearing timeouts when we have a valid session status (e.g. pending/submitted)
    if (!!session?.status && tokenConfig?.loop) {
      if (timeOutSession.current) {
        clearTimeout(timeOutSession.current);
      }
      if (timeOutDialog.current) {
        clearTimeout(timeOutDialog.current);
      }
    }
  }, [session?.status, tokenConfig?.loop]);

  const resetTimeouts = () => {
    if (timeOutSession.current) {
      clearTimeout(timeOutSession.current);
    }
    if (timeOutDialog.current) {
      clearTimeout(timeOutDialog.current);
    }
    timeOutSession.current = setTimeout(onTimeOutSession, timeoutSessionDuration);
    timeOutDialog.current = setTimeout(onTimeOutDialog, timeoutDialogDuration);
  };

  const handleAnyInteraction = useDebouncedCallback(
    () => {
      // the timeout should be only enabled if the loop functionality is enabled and if the modal is not being shown
      if (tokenConfig?.loop && !isDialogOpen) {
        // if we have a session we want to reset the timeout
        if (!!session && !session.status && !!session.createdAt) {
          // reset timeout only if the session has no status yet and has been created
          // If it has a status, it means that the user has already submitted the review in either pending or published status.
          resetTimeouts();
        }
      }
    },
    1000,
    [session, onTimeOutSession, onTimeOutDialog, tokenConfig?.loop]
  );

  useEffect(() => {
    // only listen to events if the loop functionality is enabled
    if (tokenConfig?.loop) {
      const options = { capture: true };
      // use document instead of windows and capture phase to ensure we catch all events
      // Add event listeners for various user interactions
      document.addEventListener('click', handleAnyInteraction, options);
      document.addEventListener('scroll', handleAnyInteraction, options);
      document.addEventListener('keydown', handleAnyInteraction, options);
      document.addEventListener('touchstart', handleAnyInteraction, options);

      // Cleanup event listeners on component unmount
      return () => {
        document.removeEventListener('click', handleAnyInteraction, options);
        document.removeEventListener('scroll', handleAnyInteraction, options);
        document.removeEventListener('keydown', handleAnyInteraction, options);
        document.removeEventListener('touchstart', handleAnyInteraction, options);
      };
    }
  }, [handleAnyInteraction, tokenConfig?.loop]);

  const onCloseModal = useCallback(() => {
    setIsDialogOpen(false);
    resetTimeouts();
  }, []);

  return (
    <div>
      <Modal onClose={() => {}} isOpen={isDialogOpen} contentLabel="Need more time" preventCloseOnClickOutside={true}>
        <p className="dialog-title">{t('sessionExpiring')}</p>
        <p className="dialog-description">{t('sessionExpiringDescription')}</p>
        <ActionButton onClick={onCloseModal} backgroundColor="primary" width="full">
          {t('yes')}
        </ActionButton>
      </Modal>
      {children}
    </div>
  );
};

export default SessionTimeout;

import { useCallback, useMemo } from 'react';
import { SUPPORTED_LANGUAGES } from '../utils/constants';
import i18n from '../i18n';
import { SelectOption } from '@yumpingo/yummy-components';

export const useCorrectLanguage = (refetch?: () => void, languages?: string[]) => {
  const options = useMemo(
    () =>
      SUPPORTED_LANGUAGES.filter((lng) => languages?.includes(lng.id)).sort(
        (a, b) => languages?.indexOf(a.id)! - languages?.indexOf(b.id)!
      ),
    [languages]
  );

  const handleLanguageChange = useCallback(
    (optionId: SelectOption['id']) => {
      if (optionId && i18n.language !== optionId) {
        document.documentElement.setAttribute('dir', i18n.dir(optionId));
        document.documentElement.setAttribute('lang', optionId);

        i18n.changeLanguage(optionId).then(() => {
          refetch && refetch();
          localStorage.setItem('current_lang', optionId);
        });
      }
    },
    [refetch]
  );

  return { options, handleLanguageChange };
};

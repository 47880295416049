import styled from '@emotion/styled';
import { getColorVar } from '@yumpingo/yummy-components/theme/Palette';
import React, { FC } from 'react';
import { MaxWidthContainer } from '../max-width-container/MaxWidthContainer';

export interface Props {
  leftFooterAction?: React.ReactNode;
  rightFooterAction?: React.ReactNode;
  footerAction?: React.ReactNode;
  className?: string;
}

const Wrapper = styled.div((props: { showBottomPadding: boolean }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh', // account for the brand header space
  backgroundColor: getColorVar('white'),
  padding: 'var(--page-padding)',
  paddingBottom: props.showBottomPadding ? 240 : undefined,
}));

const StickyFooter = styled(MaxWidthContainer)((props: { bottom?: number }) => ({
  position: 'fixed',
  bottom: props.bottom ?? 0,
  left: 0,
  right: 0,
  padding: 'var(--page-padding)',
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: getColorVar('white'),
}));

const LeftActionWrapper = styled.div({
  marginInlineEnd: 24,
});

const RightActionWrapper = styled.div({
  marginInlineStart: 'auto',
});

export const MainContainer: FC<Props> = ({
  leftFooterAction,
  rightFooterAction,
  footerAction,
  className,
  children,
}) => {
  const hasFooter = !!footerAction || !!leftFooterAction || !!rightFooterAction;
  return (
    <Wrapper showBottomPadding={hasFooter} className={className}>
      {children}
      {hasFooter &&
        (footerAction && leftFooterAction && rightFooterAction ? (
          <>
            <StickyFooter>{footerAction}</StickyFooter>
            <StickyFooter bottom={90}>
              {!!leftFooterAction && <LeftActionWrapper>{leftFooterAction}</LeftActionWrapper>}
              {!!rightFooterAction && <RightActionWrapper>{rightFooterAction}</RightActionWrapper>}
            </StickyFooter>
          </>
        ) : (
          <StickyFooter>
            {footerAction || (
              <>
                {!!leftFooterAction && <LeftActionWrapper>{leftFooterAction}</LeftActionWrapper>}
                {!!rightFooterAction && <RightActionWrapper>{rightFooterAction}</RightActionWrapper>}
              </>
            )}
          </StickyFooter>
        ))}
    </Wrapper>
  );
};

import React, { FC, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { fetchVisitorInfo } from '../../../../../redux/fingerprint/fingerprintStateSlice';
import { useAppDispatch } from '../../../../../redux/utils';
import { MainContainer } from '../../../../main-container/MainContainer';
import lazyWithRetry from '../../../../../utils/lazy-import-retry';

const QrScan = lazyWithRetry(() => import('../../../../../pages/entry/pages/qr-scan/QrScan'), 'QrScan');
const VenueSplash = lazyWithRetry(() => import('../../../../../pages/entry/pages/venue/VenueSplash'), 'VenueSplash');
const VenueCodeEntry = lazyWithRetry(
  () => import('../../../../../pages/entry/pages/venue/pages/venue-code-entry/VenueCodeEntry'),
  'VenueCodeEntry'
);
const TokenEntry = lazyWithRetry(() => import('../../../../../pages/entry/pages/token-entry/TokenEntry'), 'TokenEntry');

const EntryRoutes: FC = () => {
  // preload visitor info to be used later
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchVisitorInfo());
  }, []);

  return (
    <Switch>
      <Route path="/entry/venue" exact component={VenueSplash} />
      <Route
        path="/entry/venue/code/:code"
        exact
        render={({
          match: {
            params: { code },
          },
        }) =>
          code.length === 6 ? (
            <MainContainer>
              <VenueCodeEntry code={code} />
            </MainContainer>
          ) : (
            <Redirect to="/" />
          )
        }
      />
      <Route path="/entry/qr" exact render={() => <QrScan />} />
      <Route path="/entry" exact component={TokenEntry} />
      <Redirect to="/entry" />
    </Switch>
  );
};

export default React.memo(EntryRoutes);

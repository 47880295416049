import * as H from 'history';
import { createToast } from '@yumpingo/yummy-components/hooks/useSimpleToaster';

export interface UrlCallback {
  url: string;
  delay?: number;
}

export interface PostMessageCallback {
  postMessage: string | object;
}

export type Callback = UrlCallback | PostMessageCallback;

export function isUrlCallback(callback: Callback): callback is UrlCallback {
  return !!(callback as UrlCallback).url;
}

export function isPostMessageCallback(callback: Callback): callback is PostMessageCallback {
  return !!(callback as PostMessageCallback).postMessage;
}

export function executeCallback(
  history: H.History,
  callback?: Callback,
  onPopupBlocked?: (redirectUrl: string) => void
): { stopExecution: boolean; cancel?: () => void; hurry?: () => void } {
  if (callback) {
    if (isUrlCallback(callback)) {
      const goTo = () => {
        if (callback.url.startsWith('/')) {
          history.push(callback.url);
        } else {
          const newWindow = window.open(callback.url, '_blank');
          // check if browser is blocking the popup
          if (newWindow === null) {
            onPopupBlocked && onPopupBlocked(callback.url);
          } else {
            newWindow.focus();
          }
        }
      };
      const timeout = setTimeout(goTo, callback.delay || 0);
      const cancel = () => timeout && clearTimeout(timeout);
      return {
        stopExecution: !callback.delay,
        cancel,
        hurry: () => {
          if (timeout) {
            clearTimeout(timeout);
            goTo();
          }
        },
      };
    } else if (isPostMessageCallback(callback)) {
      window.parent.postMessage(callback.postMessage, '*');
    }
  }
  return { stopExecution: false };
}

export function createOnLinkBlockedToast(redirectUrl: string) {
  return createToast(
    `If you’re not redirected,\n click to proceed.`,
    'info',
    {
      text: 'Open',
      onClick: () => {
        window.open(redirectUrl, '_blank')?.focus();
      },
    },
    60000
  );
}

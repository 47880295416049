import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ErrorDisplay } from '../../../pages/error/ErrorDisplay';
import BrandLandingRoutes from './components/brand-landing-routes/BrandLandingRoutes';
import EntryRoutes from './components/entry-routes/EntryRoutes';
import ReviewRoutes from './components/review-routes/ReviewRoutes';
import UnsubscribeRoutes from './components/unsubscribe-routes/UnsubscribeRoutes';
import lazyWithRetry from '../../../utils/lazy-import-retry';

const TableNumberPage = lazyWithRetry(() => import('../../../pages/table-number/TableNumberPage'), 'TableNumberPage');
const DigitalBillPage = lazyWithRetry(() => import('../../../pages/digital-bill/DigitalBillPage'), 'DigitalBillPage');

export const AppRoutes: FC = () => {
  return (
    <Switch>
      <Route path="/bill" exact component={TableNumberPage} />
      <Route path="/bill/:billId" exact component={DigitalBillPage} />
      <Route path="/entry" component={EntryRoutes} />
      {/* Hack to support brandQr codes that were printed with wrong brand url. 2024-09-02 */}
      {/* TODO: Monitor and remove this eventually */}
      <Redirect exact from="/brand/nandossouthafrica" to="/brand/nandosSA" />

      <Route path="/brand" component={BrandLandingRoutes} />
      <Route path="/review" component={ReviewRoutes} />
      <Route path="/unsubscribe" component={UnsubscribeRoutes} />
      <Route path="/error" exact component={ErrorDisplay} />
      {/* Backwards compatability - not sure what's using this, might just be internal */}
      <Route
        path="/:venueCode"
        exact
        render={({
          match: {
            params: { venueCode },
          },
        }) => <Redirect to={`/entry/venue/code/${venueCode}`} />}
      />
      <Redirect to="/entry" />
    </Switch>
  );
};

import { LoadOptions } from '@fingerprintjs/fingerprintjs-pro';

export const environment = {
  name: import.meta.env.VITE_STAGE,
  buildVersion: import.meta.env.VITE_BUILD_VERSION,
  publicUrl: import.meta.env.BASE_URL,
  apiToken: import.meta.env.VITE_API_TOKEN,
  guestApiUrl: import.meta.env.VITE_GUEST_API_URL,
  ingestionApiUrl: import.meta.env.VITE_INGESTION_API_URL,
  sentryKey: import.meta.env.VITE_SENTRY_KEY,
  hotjarSiteId: import.meta.env.VITE_HOTJAR_SITE_ID,
  analyticsTrackingCode: import.meta.env.VITE_ANALYTICS_TRACKING_CODE,
  commitId: import.meta.env.VITE_COMMIT_ID,
  fpjs: {
    token: import.meta.env.VITE_FINGERPRINTJS_TOKEN,
    region: import.meta.env.VITE_FINGERPRINTJS_REGION,
  } as LoadOptions,
};

import useTokenParameterSetup from './useTokenParameterSetup';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { environment } from '../environment';
import * as Sentry from '@sentry/react';
import { hotjar } from 'react-hotjar';

/**
 * This hook is responsible for starting all necessary processes for our application to function properly.
 * It retrieves a token for authentication, starts the translation service, and starts Sentry and Hotjar for monitoring and error tracking.
 *
 * It is a crucial part of our application's infrastructure, as it ensures that everything is set up and running smoothly
 * before the user is able to interact with the application.
 *
 * Returns a boolean flag indicating whether the all the processes are completed or not.
 *
 * This hook should be used only in the {@link App} component before displaying/mounting the actual app.
 */
const useBoostrapProcess = () => {
  const tokenReady = useTokenParameterSetup();
  const translations = useTranslation();

  /**
   * Setups Sentry.
   * Sentry  is used to track and report errors that occur within the application
   */
  useEffect(() => {
    // disable sentry for development or avoid setting it up when no key provided
    if (!environment.sentryKey || environment.name === 'development') {
      return;
    }

    try {
      Sentry.init({
        dsn: environment.sentryKey,
        environment: environment.name,
        release: environment.commitId,
      });
    } catch (e) {
      console.log(e);
    }
  }, []);

  /**
   * Setups Hotjar.
   * Hotjar is used to monitor the user's interactions with the application and track their behavior.
   */
  useEffect(() => {
    // disable hotjar for development or avoid setting it up when no key provided
    if (!environment.hotjarSiteId || environment.name === 'development') {
      return;
    }
    try {
      hotjar.initialize(parseInt(environment.hotjarSiteId), 6);
      // console.log('Is hotjar initialised: ' + hotjar.initialized());
    } catch (e) {
      console.log(e);
    }
  }, []);

  return Boolean(tokenReady && translations.ready);
};

export default useBoostrapProcess;

import { FC, useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { UpdateEntity, UpdateType, useGuestJourney } from '../../../utils/guest-journey';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import { useSelector } from 'react-redux';
import { selectSessionExperienceDriversSelector } from '../../../redux/experienceDrivers/experienceDriversStateSelectors';

/**
 *  This component is responsible for tracking page views and exp driver state changes
 *  and triggering the guest journey events
 */
export const GuestJourney: FC = () => {
  const location = useLocation();
  const gj = useGuestJourney();
  const experienceDrivers = useSelector(selectSessionExperienceDriversSelector);
  const prevExpDrivers = useRef<string>('');
  const firstVisit = useRef(true);

  // This effect is responsible for tracking page views
  useEffect(() => {
    if (firstVisit.current) {
      firstVisit.current = false;
      gj.trackSessionStarted();
    }

    gj.trackPageView(location.pathname + location.search);
  }, [location.pathname, location.search]);

  const trackExpDriverUpdate = useCallback(() => {
    gj.trackReviewUpdated({
      type: UpdateType.Add,
      entity: UpdateEntity.ExperienceDriver,
      data: experienceDrivers,
    });
    prevExpDrivers.current = JSON.stringify(experienceDrivers);
  }, [experienceDrivers, gj]);

  const debouncedTrack = useDebouncedCallback(() => trackExpDriverUpdate(), 1000);

  // This effect is responsible for tracking exp driver state changes
  // TODO: move this into the reducer once we move to redux
  useEffect(() => {
    // only track exp driver chages when the payload actually changes
    if (
      (experienceDrivers?.length || prevExpDrivers.current.length) &&
      JSON.stringify(experienceDrivers) !== prevExpDrivers.current
    ) {
      // the update is debounced to avoid tracking too many events when users are typing in the text field
      debouncedTrack();
    }
  }, [experienceDrivers, gj]);

  return null;
};

import { ApiError } from '../models/ApiError';

// TODO: refactor to yummy utils
export const apiGET = <TResult>(
  url: string,
  token?: string,
  params?: string[][] | Record<string, string> | string | URLSearchParams,
  headers?: RequestInit['headers']
) => {
  const safeHeaders = { ...getHeaders(token), ...headers };
  const safeURL = `${url}${params ? `?${new URLSearchParams(params)}` : ''}`;

  return fetch(safeURL, { headers: safeHeaders }).then<TResult>(convertToJson);
};

export function apiRequest<T>(url: string, token?: string, params?: any, init?: RequestInit): Promise<T> {
  return fetch(`${url}${params ? `?${new URLSearchParams(params)}` : ''}`, {
    ...init,
    headers: getHeaders(token, init),
  }).then((res) => convertToJson(res));
}

export function apiPost<T>(url: string, token?: string, body?: object, init?: RequestInit): Promise<T> {
  return apiRequestWithBody(url, token, body, { ...init, method: 'POST' });
}

export function apiPatch<T>(url: string, token: string, body?: object, init?: RequestInit): Promise<T> {
  return apiRequestWithBody(url, token, body, { ...init, method: 'PATCH' });
}

function apiRequestWithBody<T>(url: string, token?: string, body?: object, init?: RequestInit): Promise<T> {
  return fetch(url, {
    method: 'POST',
    ...init,
    headers: getHeaders(token, init),
    body: JSON.stringify(body),
  }).then((res) => convertToJson(res));
}

function getHeaders(token?: string, init?: RequestInit) {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
    ...init?.headers,
  };
}

async function convertToJson<T>(res: Response): Promise<T> {
  if (res.ok && res.status >= 200 && res.status <= 299) {
    return res.json().catch(() => undefined) as Promise<T>;
  }
  const error: ApiError<any> = {
    statusText: res.statusText,
    error: new Error(res.statusText),
  };
  try {
    error.body = await res.text();
    error.json = JSON.parse(error.body);
  } catch {}
  throw error;
}

/**
 * Returns the arguments type of the provided function except the first one
 */
type ParametersTail<T extends (...args: any) => any> = T extends (ignored: infer _, ...args: infer P) => any
  ? P
  : never;

export interface CachableServiceFactory<TResult, TParams extends any[]> {
  (get: typeof apiGET, ...args: TParams): Promise<TResult>;
}

/**
 * Takes a factory function that fetches data from a remote endpoint and returns a cached version of the same function
 * @param fn
 */
export const createCachableService = <TParams extends any[], TResult>(fn: CachableServiceFactory<TResult, TParams>) => {
  type P = ParametersTail<typeof fn>;
  const cache = new Map<string, Promise<TResult>>();

  const cachableGET = (
    url: string,
    token?: string,
    params?: string[][] | Record<string, string> | string | URLSearchParams,
    headers?: RequestInit['headers']
  ) => {
    const key = generateCacheKey(url, token, headers);

    if (cache.has(key)) return cache.get(key);

    const promise = apiGET<TResult>(url, token, params, headers).then((result) => {
      cache.set(key, Promise.resolve(result));

      setTimeout(() => cache.delete(key), 5000);

      return result;
    });

    cache.set(key, promise);

    return promise;
  };

  return (...args: P) => fn(cachableGET as typeof apiGET, ...args);
};

const isHeaders = (headers: any): headers is Record<string, string> => {
  return typeof headers === 'object' && !Array.isArray(headers) && headers !== null;
};

const generateCacheKey = (url: string, token?: string, headers?: RequestInit['headers']) => {
  const tokenBit = `--${token}` || '';
  const headersBit = isHeaders(headers) ? `--${headers['Accept-Language']}` : '';

  return `GET--${url}${tokenBit}${headersBit}`;
};

import React, { FC } from 'react';
import { Frame } from '../frame/Frame';
import Modal, { fitWidthDialogStyles } from '@yumpingo/yummy-components/components/atoms/Modal';
import ActionButton from '@yumpingo/yummy-components/components/molecules/ActionButton';
import { useTranslation } from 'react-i18next';
import { BUTTON_HEIGHT } from '../../App.config';

interface Props {
  url: string;
  title: string;
  isOpen: boolean;
  onClose: () => void;
  'data-cy'?: string;
}

const ModalFrame: FC<Props> = ({ url, title, isOpen, onClose, 'data-cy': dataCy }) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      contentLabel={title}
      customStyles={{
        ...fitWidthDialogStyles,
        content: { ...fitWidthDialogStyles.content, padding: '0.5rem', maxWidth: 'calc(100vw - 1rem)' },
      }}
    >
      <div style={{ maxWidth: '24rem', marginBottom: '20px' }} data-cy={dataCy}>
        <Frame src={url} title={title} className="mb-4" height="calc(100vh - 8rem)" />
        <ActionButton
          onClick={onClose}
          width="full"
          height={BUTTON_HEIGHT}
          backgroundColor="primary"
          data-cy={dataCy && `${dataCy}-close`}
        >
          {t('ok')}
        </ActionButton>
      </div>
    </Modal>
  );
};

export default ModalFrame;

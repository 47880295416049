import { createAction } from '@reduxjs/toolkit';
import {
  BeginSessionActionPayload,
  RemoveReviewActionPayload,
  RemoveAllMissingReviewsActionPayload,
  AddReviewActionPayload,
  FilterOutReviewsActionPayload,
  AddAnswerActionPayload,
  RemoveAnswerActionPayload,
  PunchhSubscriptionActionPayload,
  PunchhEClubSubscriptionActionPayload,
} from './models';

export const beginSessionAction = createAction<BeginSessionActionPayload>('session/begin');
export const removeReviewAction = createAction<RemoveReviewActionPayload>('review/remove');
export const removeAllMissingReviewsAction =
  createAction<RemoveAllMissingReviewsActionPayload>('review/removeAllMissing');
export const addReviewAction = createAction<AddReviewActionPayload>('review/add');
export const filterOutReviewsAction = createAction<FilterOutReviewsActionPayload>('review/filterOut');
export const addAnswerAction = createAction<AddAnswerActionPayload>('answer/add');
export const removeAnswerAction = createAction<RemoveAnswerActionPayload>('answer/remove');
export const addPunchhSubscriptionAction = createAction<PunchhSubscriptionActionPayload>('punchh/subscribe');
export const addPunchhEClubSubscriptionAction =
  createAction<PunchhEClubSubscriptionActionPayload>('punchh-eclub/subscribe');

import { REVIEW_ROUTES } from '../components/routing/app-routes/components/review-routes/ReviewRoutes.config';
import { FilteredMenuItem } from '../models/FilteredMenuItem';
import { getSessionFromStorage } from '../redux/sessionState/sessionSelectors';

/**
 * Retrieves the billId from the URL, the session, or undefined
 */
export const getBillId = () => {
  const session = getSessionFromStorage();

  const billId = new URL(window.location.href).searchParams.get('billId') || session?.billId || undefined;

  return billId;
};

export const getBillItemInfo = (menu: string | any[]) => {
  let id;
  if (menu.length > 0) {
    id = menu[0].dishId || (menu[0] as FilteredMenuItem).posDishId;
  }
  const billItemPath = id ? `${REVIEW_ROUTES.food}/items/${id}` : '';

  const isBillTwoItemsOrLess = menu.length <= 2;
  const isBillTwoItems = menu.length === 2;

  return { billItemPath, isBillTwoItemsOrLess, isBillTwoItems };
};

import { YesNoResponse } from '@yumpingo/yummy-components/';
import { stringEqual } from '@yumpingo/yummy-components/utils/stringEqual';
import { FilteredMenuItem } from './FilteredMenuItem';
import { getMenuItemId, MenuItem } from './FoodConfig';
import { Flow } from './Flow';

export enum ItemState {
  NotReviewed,
  Reviewed,
  Missing,
  MultipleItemReviewInProgress,
}

export interface ItemReview {
  name: string;
  dishId?: string;
  posDishId?: string;
  categoryId?: string;
  sectionId?: string;
  createdAt?: string;
  overall?: YesNoResponse;
  look?: YesNoResponse;
  taste?: YesNoResponse;
  portion?: YesNoResponse;
  value?: YesNoResponse;
  comment?: string;
  missing?: boolean;
}

export function isMatchingItemReview(flow: Flow, item: MenuItem, review: ItemReview): boolean {
  return isMatchingItemReviewById(flow, getMenuItemId(flow, item), review);
}

export function findMatchingItemReview(flow: Flow, item: MenuItem, reviews: ItemReview[]): ItemReview | undefined {
  const id = getMenuItemId(flow, item);
  return reviews.find((review) => isMatchingItemReviewById(flow, id, review));
}

function isMatchingItemReviewById(flow: Flow, id: string, review: ItemReview): boolean {
  return stringEqual(id, flow === 'standard' ? review.dishId : review.posDishId);
}

export function getAllReviewsForItem(flow: Flow, item: MenuItem, reviews: ItemReview[] = []): ItemReview[] {
  const id = getMenuItemId(flow, item);
  return reviews.filter((review) => isMatchingItemReviewById(flow, id, review));
}

export function getReviewCountForItem(flow: Flow, item: MenuItem, reviews: ItemReview[] = []): number {
  return getAllReviewsForItem(flow, item, reviews).length;
}

export function isItemReviewComplete(flow: Flow, item: MenuItem, reviews: ItemReview[] = []): boolean {
  if (flow === 'standard') {
    return !!findMatchingItemReview(flow, item, reviews);
  }
  return getReviewCountForItem(flow, item, reviews) === (item as FilteredMenuItem).quantity;
}

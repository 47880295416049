import { RootState } from '../store';
import { WithExpiry } from '../../models/WithExpiry';
import { createSelector } from 'reselect';
import { MISSING_ITEMS_STORAGE_KEY, MissingItemsState } from './missingItemsStateSlice';

export type MissingItemsWithExpiry = WithExpiry<MissingItemsState>;

const missingItemsRootState = (state: RootState) => state.missingItems;

export const selectMissingItemsState = createSelector(missingItemsRootState, (missingItemsState) => {
  // if we have it in redux memory, return it
  if (missingItemsState) return missingItemsState;

  // otherwise, check local storage
  const missingItemsWithExpiry: MissingItemsWithExpiry = JSON.parse(
    localStorage.getItem(MISSING_ITEMS_STORAGE_KEY) as string
  );

  if (missingItemsWithExpiry && Date.now() <= missingItemsWithExpiry.expiresAt) {
    // if not expired, return it
    return missingItemsWithExpiry.data;
  } else {
    // otherwise, remove it from local storage and return null
    localStorage.removeItem(MISSING_ITEMS_STORAGE_KEY);
    return null;
  }
});

export const selectShouldMissingItemsModalVisibileState = createSelector(
  selectMissingItemsState,
  (missingItemsState) => {
    return missingItemsState?.showMissingItemsModal || false;
  }
);

export const selectMissingItemsQueryState = createSelector(selectMissingItemsState, (missingItemsState) => {
  return missingItemsState?.missingItemsQuery || '';
});

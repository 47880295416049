import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import lazyWithRetry from '../../../../../utils/lazy-import-retry';

const BrandLanding = lazyWithRetry(
  () => import('../../../../../pages/entry/pages/brand-landing/BrandLanding'),
  'BrandLanding'
);
const VenueServiceStyles = lazyWithRetry(
  () => import('../../../../../pages/entry/pages/brand-landing/VenueServiceStyles'),
  'VenueServiceStyles'
);

const BrandLandingRoutes: FC = () => (
  <Switch>
    <Route path="/brand/:urlName" exact component={BrandLanding} />
    <Route path="/brand/:urlName/venue/:venueUid/service-styles" exact component={VenueServiceStyles} />
  </Switch>
);

export default React.memo(BrandLandingRoutes);

import styled from '@emotion/styled';
import { handleError } from '@yumpingo/yummy-components/utils/error-handler';
import React, { FC, useState } from 'react';
import ActionButton from '@yumpingo/yummy-components/components/molecules/ActionButton';
import Input from '@yumpingo/yummy-components/components/molecules/Input';
import { BUTTON_HEIGHT } from '../../App.config';
import { CentralisedContainer } from '../../components/centralised-container/CentralisedContainer';
import { environment } from '../../environment';
import { apiPost } from '../../utils/api-request';
import { ErrorDisplay } from '../error/ErrorDisplay';
import { selectTokenBrandName, selectTokenState, selectTokenUserEmail } from '../../redux/token/tokenSelectors';
import { useSelector } from 'react-redux';

const Title = styled.h2({
  marginBottom: 40,
});

const SpacedText = styled.p({
  marginBottom: 40,
});

const ActionButtonContainer = styled(ActionButton)({
  marginTop: 32,
});

const InputContainer = styled(Input)({
  width: '100%',
  input: {
    textAlign: 'center',
  },
});

export const Unsubscribe: FC = () => {
  const apiToken = useSelector(selectTokenState);
  const brandName = useSelector(selectTokenBrandName);
  const userEmail = useSelector(selectTokenUserEmail);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  if (!userEmail) {
    return <ErrorDisplay description="The link you clicked may have expired." />;
  }

  const submit = async () => {
    try {
      await apiPost<void>(`${environment.guestApiUrl}/unsubscribe`, apiToken!);
      setSubmitted(true);
    } catch (error) {
      handleError(error);
      setError(true);
    }
  };

  if (error) {
    return (
      <ErrorDisplay
        description="We were unable to unsubscribe you."
        primaryAction={() => {
          setError(false);
          submit();
        }}
      />
    );
  }

  return (
    <CentralisedContainer>
      {submitted ? (
        <>
          <Title>Sorry to see you go!</Title>
          <SpacedText>
            You are now unsubscribed from {brandName} Yumpingo feedback emails unless you tell us otherwise.
          </SpacedText>
          <SpacedText>
            <b>Thanks for all your previous feedback!</b>
          </SpacedText>
        </>
      ) : (
        <>
          <Title>Are you sure?</Title>
          <SpacedText>Your feedback really matters!</SpacedText>
          <SpacedText>You won’t receive {brandName} Yumpingo feedback emails in the future.</SpacedText>
          <InputContainer value={userEmail} disabled />
          <ActionButtonContainer width="full" backgroundColor="primary" height={BUTTON_HEIGHT} onClick={submit}>
            Unsubscribe
          </ActionButtonContainer>
        </>
      )}
    </CentralisedContainer>
  );
};

import { Font } from '@yumpingo/yummy-components';

export const fetchFonts = async (font: Font) => {
  const fonts = font.weights.map((weight) => {
    const fontFile = new FontFace(
      font.name,
      `url('${weight.url}') ${weight.format ? `format('${weight.format}')` : ''}`,
      {}
    );
    document.fonts.add(fontFile);
    return fontFile.load();
  });

  await Promise.all(fonts || []);
};

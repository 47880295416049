import FP, { FullIpExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro';
import { environment } from '../../../environment';
import { Session } from '../../../models/Session';
import { apiPatch, apiPost } from '../../../utils/api-request';

export type VisitorInfo =
  | Omit<FullIpExtendedGetResult, 'fingerprint' | 'ip' | 'ipLocation' | 'incognito' | 'visitorFound' | 'requestId'>
  | VisitorInfoError;

export type VisitorInfoError = { region: string; error: any };

export async function submitSession(
  session: Session,
  publish: boolean,
  apiToken: string,
  fingerprintVisitorInfo: VisitorInfo
): Promise<Session> {
  if (session.status === 'published') {
    return session;
  }
  const modifiedSession: Session = {
    ...session,
    status: publish ? 'published' : 'pending',
    finishedAt: publish ? new Date().toISOString() : undefined,
  };

  const updated = session.id
    ? await patchSession(modifiedSession, apiToken)
    : await postSession(modifiedSession, apiToken, fingerprintVisitorInfo);

  return { ...modifiedSession, ...updated };
}

async function postSession(session: Session, apiToken: string, visitorInfo: VisitorInfo): Promise<Session> {
  return await apiPost<Session>(
    `${environment.ingestionApiUrl}/guest/sessions/`,
    apiToken,
    {
      ...normalise(session),
      visitorInfo,
    },
    { headers: { 'Accept-Language': navigator.language } }
  );
}

async function patchSession(session: Session, apiToken: string): Promise<Session> {
  return await apiPatch<Session>(
    `${environment.ingestionApiUrl}/guest/sessions/${session.id}`,
    apiToken,
    normalise(session)
  );
}

export async function getVisitorInfo(): Promise<VisitorInfo | null> {
  if (!environment.fpjs?.token) {
    return Promise.resolve(null);
  }
  try {
    const fp = await FP.load(environment.fpjs);
    const { browserName, browserVersion, device, os, osVersion, visitorId, firstSeenAt, lastSeenAt, confidence } =
      await fp.get({ extendedResult: true });
    return {
      browserName,
      browserVersion,
      device,
      os,
      osVersion,
      visitorId,
      region: environment.fpjs.region,
      firstSeenAt,
      lastSeenAt,
      confidence,
    };
  } catch (err: any) {
    let error = err && err.error ? err.error : err;
    if (typeof error !== 'object') {
      error = { message: error };
    }
    return { region: environment.fpjs.region, error } as VisitorInfo;
  }
}

function normalise(session: Session): Session {
  return {
    ...session,
    answers: session.answers.map((a) => {
      if ('answer' in a) {
        return { ...a, answer: String(a.answer) };
      }
      return a;
    }),
  };
}

import { useEffect, useMemo, useRef } from 'react';
import { apiPost } from './api-request';
import { environment } from '../environment';
import { useSelector } from 'react-redux';
import { selectTokenDecoded } from '../redux/token/tokenSelectors';

interface EventBase {
  version?: number;
  timestamp?: string;
  eventTimestamp: string;
  type: string;
  guestJourneyId: string;
  brandId: string;
  venueId: string;
  data?: any;
}

export enum GuestJourneyEventType {
  PageView = 'PageView',
  ReviewUpdated = 'ReviewUpdated',
  SessionStarted = 'SessionStarted',
}

export enum UpdateType {
  Add = 'add',
  Remove = 'remove',
}

export enum UpdateEntity {
  Question = 'question',
  DishReview = 'dishReview',
  ExperienceDriver = 'experienceDriver',
  Complaint = 'complaint',
  Subscribe = 'subscribe',
  Metadata = 'metadata',
}

export interface ReviewUpdatedEvent extends EventBase {
  type: GuestJourneyEventType.ReviewUpdated;
  data: {
    type: UpdateType;
    entity: UpdateEntity;
    id?: string;
    data: any;
  };
}

export const createId = (event: ReviewUpdatedEvent['data'], gjid?: string) => {
  if (event.entity === UpdateEntity.DishReview) {
    return [event.data.dishId || event.data.posDishId, gjid, new Date(event.data.createdAt || '').getTime()].join('-');
  } else if (event.entity === UpdateEntity.Question) {
    return [event.data.questionId, gjid].join('-');
  }
};

/**
 *  Hook to track guest journey events and page views and send them to the API
 *  If there is no token in the app yet (and thus no guest journey id), the events are queued and sent once the token is available.
 *
 *  This hook exposes the following methods:
 *  - pageView(page: string): track a page view
 *  - trackSessionStarted(): track a session started event
 *  - track(event: GuestJourneyEventType, data: any): track a custom event
 *  - trackReviewUpdated(data: ReviewUpdatedEvent['data']): track a review update event
 */
export const useGuestJourney = () => {
  const tokenState = useSelector(selectTokenDecoded);
  const queue = useRef<any[]>([]);

  const commonDimensions = useMemo(
    () =>
      tokenState && {
        brandId: tokenState.buid,
        venueId: tokenState.vuid,
        guestJourneyId: tokenState.gjid,
      },
    [tokenState]
  );

  // this effect sends queued events to the API once the token is available
  useEffect(() => {
    // wait for token to send events
    if (commonDimensions?.venueId && queue.current.length > 0) {
      const events = queue.current.map((item) => ({
        ...item,
        ...commonDimensions,
      }));
      sendToApi(events);
      queue.current = [];
    }
  }, [queue, commonDimensions]);

  const track = (event: GuestJourneyEventType, data: any) => {
    // queue the event if there is no token yet
    if (!commonDimensions?.venueId) {
      queue.current = [...queue.current, { type: event, eventTimestamp: new Date().toISOString(), data }];
      return;
    }

    const guestEvent = {
      type: event,
      eventTimestamp: new Date().toISOString(),
      ...commonDimensions,
      data: {
        ...data,
      },
    };
    sendToApi([guestEvent]);
  };

  const sendToApi = async (events: any[]) => {
    try {
      await apiPost<void>(`${environment.guestApiUrl}/guest/events`, undefined, events);
    } catch (e) {
      // dont throw error if guest journey event fails
      console.error('Error sending guest journey event', e);
    }
  };

  const trackReviewUpdated = (data: ReviewUpdatedEvent['data']) => {
    const eventData = {
      ...data,
      id: createId(data, commonDimensions?.guestJourneyId),
    };
    track(GuestJourneyEventType.ReviewUpdated, eventData);
  };

  const trackPageView = (page: string) => {
    track(GuestJourneyEventType.PageView, {
      page,
    });
  };

  const trackSessionStarted = () => {
    // only track once per browser session and guest journey
    const key = `trackSessionStarted-${commonDimensions?.guestJourneyId}`;
    const sessionStarted = sessionStorage?.getItem(key);
    if (sessionStarted) {
      return;
    }

    track(GuestJourneyEventType.SessionStarted, {
      referrer: document?.referrer,
      screenWidth: window?.screen?.width || window?.innerWidth,
      screenHeight: window?.screen?.height || window?.innerHeight,
      // @ts-ignore
      platform: navigator?.userAgentData?.platform || navigator?.platform || 'unknown',
      userAgent: navigator?.userAgent,
    });
    sessionStorage?.setItem(key, 'true');
  };

  return {
    track,
    trackPageView,
    trackReviewUpdated,
    trackSessionStarted,
  };
};

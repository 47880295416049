import styled from '@emotion/styled';
import { MainContainer } from '../main-container/MainContainer';

export const CentralisedContainer = styled(MainContainer)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
});

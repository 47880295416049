import React from 'react';
import { useSelector } from 'react-redux';
import { selectReviewConfigTheme } from '../redux/reviewConfig/reviewConfigSelectors';
import { DEFAULT_THEME, ThemeProvider } from '@yumpingo/yummy-components';

export const CustomThemeProvider = ({ children }: any) => {
  const theme = useSelector(selectReviewConfigTheme) || DEFAULT_THEME;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

import { createSlice } from '@reduxjs/toolkit';
import { THREE_HOURS } from '../utils';
import { MissingItemsWithExpiry } from './missingItemsStateSelectors';

export interface MissingItemsState {
  showMissingItemsModal: boolean;
  missingItemsQuery: string;
}

export const MISSING_ITEMS_STORAGE_KEY = btoa('Review/MissingItems');

const missingItemsSlice = createSlice({
  name: 'missingItems',
  initialState: null as MissingItemsState | null,
  reducers: {
    setShowMissingItemsModal: (state, action: { payload: boolean }) => {
      const expirationTime = Date.now() + THREE_HOURS;
      const missingItemsWithExpiry: MissingItemsWithExpiry = {
        data: { showMissingItemsModal: action.payload, missingItemsQuery: state?.missingItemsQuery || '' },
        expiresAt: expirationTime,
      };
      localStorage.setItem(MISSING_ITEMS_STORAGE_KEY, JSON.stringify(missingItemsWithExpiry));
      if (state) {
        state.showMissingItemsModal = action.payload;
      } else {
        return { showMissingItemsModal: action.payload, missingItemsQuery: '' };
      }
    },
    setMissingItemsQuery: (state, action: { payload: string }) => {
      const expirationTime = Date.now() + THREE_HOURS;
      const missingItemsWithExpiry: MissingItemsWithExpiry = {
        data: { showMissingItemsModal: state?.showMissingItemsModal || false, missingItemsQuery: action.payload },
        expiresAt: expirationTime,
      };
      localStorage.setItem(MISSING_ITEMS_STORAGE_KEY, JSON.stringify(missingItemsWithExpiry));
      if (state) {
        state.missingItemsQuery = action.payload;
      } else {
        return { showMissingItemsModal: false, missingItemsQuery: action.payload };
      }
    },
    resetMissingItems: (state) => {
      localStorage.removeItem(MISSING_ITEMS_STORAGE_KEY);
      return null;
    },
  },
});

export const { setShowMissingItemsModal, setMissingItemsQuery, resetMissingItems } = missingItemsSlice.actions;

export default missingItemsSlice.reducer;

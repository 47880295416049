import styled from '@emotion/styled';
import React, { FC } from 'react';

interface Props {
  src?: string;
  title: string;
  className?: string;
  height?: string;
}

const Container = styled.iframe(
  {
    width: '100%',
    border: 0,
  },
  ({ height }) => ({ height })
);

export const Frame: FC<Props> = ({ src, title, className, height = '100%' }) =>
  src ? <Container className={className} src={src} title={title} height={height}></Container> : null;

import styled from '@emotion/styled';
import React, { FC, useMemo } from 'react';
import Icon, { IconType } from '@yumpingo/yummy-components/components/atoms/Icon';
import { getColorVar } from '@yumpingo/yummy-components/theme/Palette';
import ActionButton from '@yumpingo/yummy-components/components/molecules/ActionButton';
import { BUTTON_HEIGHT } from '../../App.config';
import { CentralisedContainer } from '../../components/centralised-container/CentralisedContainer';
import useQueryString from '@yumpingo/yummy-components/hooks/useQueryString';
import { useSelector } from 'react-redux';
import { selectErrorState } from '../../redux/global/globalStateSelectors';

const ErrorIcon = styled(Icon)({
  paddingBottom: '3rem',
});

const Title = styled.label({
  color: getColorVar('grey1'),
  marginBottom: 4,
});

const Description = styled.p({
  color: getColorVar('grey2'),
  marginBottom: 48,
});

interface Props {
  icon?: IconType;
  title?: string;
  description?: string;
  primaryAction?: () => void;
  primaryText?: string;
  secondaryAction?: () => void;
  secondaryText?: string;
}

export const ErrorDisplay: FC<Props> = (props) => {
  const {
    icon = 'error',
    title,
    description,
    primaryAction,
    primaryText = 'Try again',
    secondaryAction,
    secondaryText = 'Go home',
  } = props;

  const error = useSelector(selectErrorState);
  const [errorCode] = useQueryString('code');

  const errorTitle = useMemo(() => {
    return title || error || errorCode === 'INVALID_JWT' ? 'Review has expired' : 'Something went wrong';
  }, [title, error, errorCode]);

  const errorDescription = useMemo(() => {
    return description || errorCode === 'INVALID_JWT'
      ? 'Unfortunately, time to complete the review has passed.'
      : undefined;
  }, [description, errorCode]);

  return (
    <CentralisedContainer>
      <ErrorIcon type={icon} size="64" color="grey2" />
      <Title>{errorTitle}</Title>
      {errorDescription && <Description>{errorDescription}</Description>}
      {primaryAction && (
        <ActionButton backgroundColor="primary" height={BUTTON_HEIGHT} width="large" onClick={primaryAction}>
          {primaryText}
        </ActionButton>
      )}
      {secondaryAction && (
        <ActionButton
          backgroundColor="grey3"
          height={BUTTON_HEIGHT}
          width="large"
          onClick={secondaryAction}
          className="mt-4"
        >
          {secondaryText}
        </ActionButton>
      )}
    </CentralisedContainer>
  );
};

import React, { useCallback } from 'react';
import { IconActionButton } from '@yumpingo/yummy-components';
import { customHistory } from '../routing/custom-history-router/CustomHistoryRouter';
import { REVIEW_ROUTES } from '../routing/app-routes/components/review-routes/ReviewRoutes.config';
import { retrieveDecodedToken } from '../../utils/token-utils';
import { getBillId } from '../../utils/digi-bill';

import './ViewCheckWrapper.style.scss';

const ViewCheck = () => {
  const billId = getBillId();
  const config = retrieveDecodedToken().cfg;
  const redirectToBill = useCallback(() => customHistory.push(`/bill/${billId}`), [billId]);
  const brandPages = [REVIEW_ROUTES.food, REVIEW_ROUTES.subscribe, REVIEW_ROUTES.complaint, REVIEW_ROUTES.final];
  const transparent = brandPages.includes(customHistory.location.pathname);

  // If we don't have a billId or we do have a billId, but we're not a digital bill flow, don't show the view check button
  if (!billId || (billId && config?.flow !== 'db')) {
    return null;
  }

  return (
    <IconActionButton
      type="menu"
      backgroundColor={transparent ? 'transparent' : 'grey5'}
      borderColor={transparent ? 'white' : undefined}
      color={transparent ? 'white' : 'grey1'}
      onClick={redirectToBill}
      className="yum-view-check-button"
    >
      View check
    </IconActionButton>
  );
};

export default React.memo(ViewCheck);

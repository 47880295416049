import { environment } from '../environment';
import { BrandQrCodeResponse } from '../models/BrandQrCodeResponse';
import { TokenResponse } from '../models/TokenResponse';
import { apiGET } from '../utils/api-request';
import { retrieveToken } from '../utils/token-utils';

export async function getBrandQrCodeConfig(brandUrlName: string, lang = 'en'): Promise<BrandQrCodeResponse> {
  const headers = {
    'Accept-Language': lang,
  };
  const url = new URL(`${environment.guestApiUrl}/landing/brand/${brandUrlName}`);
  const apiToken = retrieveToken();

  return apiGET<BrandQrCodeResponse>(url.toString(), apiToken!, undefined, headers);
}

export async function getBrandQrToken(venueUid: string, serviceStyleId: string, lang = 'en', channel?: string) {
  const headers = {
    'Accept-Language': lang,
  };
  const url = new URL(
    `${environment.guestApiUrl}/landing/venue/${venueUid}/service-style/${serviceStyleId}?preventRedirect=true${
      channel ? `&channel=${channel}` : ''
    }`
  );
  const apiToken = retrieveToken();

  return apiGET<TokenResponse>(url.toString(), apiToken!, undefined, headers);
}

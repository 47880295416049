import { combineReducers, configureStore } from '@reduxjs/toolkit';
import brandConfigReducer from './brandConfig/brandConfigSlice';
import reviewItemReducer from './currentItemReviewState/currentItemReviewStateSlice';
import reviewConfigReducer from './reviewConfig/reviewConfigSlice';
import sessionReducer from './sessionState/sessionSlice';
import tokenReducer from './token/tokenSlice';
import fingerprintReducer from './fingerprint/fingerprintStateSlice';
import globalReducer from './global/globalStateSlice';
import experienceDriversReducer from './experienceDrivers/experienceDriversStateSlice';
import missingItemsReducer from './missingItems/missingItemsStateSlice';
import { guestJourneyMiddleware } from './middleware/guestJourneyMiddleware';

const rootReducer = combineReducers({
  brandConfig: brandConfigReducer,
  reviewItem: reviewItemReducer,
  reviewConfig: reviewConfigReducer,
  session: sessionReducer,
  token: tokenReducer,
  fingerprint: fingerprintReducer,
  global: globalReducer,
  experienceDrivers: experienceDriversReducer,
  missingItems: missingItemsReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(guestJourneyMiddleware),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;

import { createSelector } from 'reselect';
import { RootState } from '../store';
import { Session } from '../../models/Session';
import { WithExpiry } from '../../models/WithExpiry';

export type SessionWithExpiry = WithExpiry<Session>;

const STORAGE_KEY = btoa('Review/Session');

const sessionRootState = (state: RootState) => state.session;

export const selectSessionState = createSelector(sessionRootState, (session) => {
  if (session) return session;

  return getSessionFromStorage();
});

export const selectSessionReviews = createSelector(selectSessionState, (session) => session?.reviews);

export const selectSessionMissingDishes = createSelector(selectSessionState, (session) =>
  session?.reviews.filter((r) => r.missing)
);

export const selectSessionNegativeReviews = createSelector(selectSessionReviews, (reviews) =>
  reviews?.filter((r) => !r.missing && r.overall === -1)
);

export const selectSessionReviewed = createSelector(selectSessionState, (session) => {
  return !!session?.finishedAt;
});

export const selectSessionHasMinimumDataSelector = createSelector(selectSessionState, (session) => {
  return session?.reviews?.length || session?.answers?.length; // if we have reviews or answers we have the minimum required to send the session
});

export const getSessionFromStorage = () => {
  const sessionWithExpiry: SessionWithExpiry = JSON.parse(localStorage.getItem(STORAGE_KEY) as string);

  if (sessionWithExpiry && Date.now() <= sessionWithExpiry.expiresAt) {
    return sessionWithExpiry.data;
  } else {
    localStorage.removeItem(STORAGE_KEY);
    return null;
  }
};

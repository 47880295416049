import { useMemo } from 'react';
import { isValidDayOfMonth } from '../../../utils/date';

export function useValidateDate(day?: string, month?: string, year?: string) {
  const invalidDate = useMemo(() => {
    if (day && month && year && parseInt(year) >= 1900) {
      // if all fields are filled then we should check if it's a valid date
      const monthNormalised = parseInt(month) - 1;
      const yearNumber = parseInt(year);
      const dayNumber = parseInt(day);
      const date = new Date();
      date.setFullYear(yearNumber, monthNormalised, dayNumber);
      date.setHours(0, 0, 0, 0);
      if (
        monthNormalised < 0 ||
        monthNormalised > 11 ||
        !isValidDayOfMonth(dayNumber, monthNormalised, yearNumber) ||
        date.getTime() >= new Date().getTime()
      ) {
        // invalid date
        return true;
      }
    } else if (!day && !month && !year) {
      // if they are all empty then it's a valid case.
      return false;
    } else {
      // if one or more fields are empty then it's invalid.
      return true;
    }
  }, [day, month, year]);

  const date = useMemo(() => {
    if (day && month && year) {
      const date = new Date();
      date.setUTCFullYear(parseInt(year), parseInt(month) - 1, parseInt(day));
      date.setUTCHours(0, 0, 0, 0);
      return date.toISOString();
    }
  }, [day, month, year]);

  return { invalidDate, date };
}

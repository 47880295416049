import I18n from '../i18n';

/**
 * Policy documents links
 */
const policyDocs = Object.freeze({
  privacy: {
    en: 'https://static.yumpingo.com/yumpingo-privacy-policy.html',
    'en-UK': 'https://static.yumpingo.com/yumpingo-privacy-policy.html',
    'en-US': 'https://static.yumpingo.com/yumpingo-privacy-policy.html',
    'ar-AE': 'https://static.yumpingo.com/local-policies/yumpingo-privacy-policy-arabic.html',
    'es-PR': 'https://static.yumpingo.com/local-policies/yumpingo-privacy-policy-espanol.html',
  },
  cookies: {
    en: 'https://static.yumpingo.com/yumpingo-cookies-policy.html',
    'en-UK': 'https://static.yumpingo.com/yumpingo-cookies-policy.html',
    'en-US': 'https://static.yumpingo.com/yumpingo-cookies-policy.html',
    'ar-AE': 'https://static.yumpingo.com/local-policies/yumpingo-cookie-policy-arabic.html',
    'es-PR': 'https://static.yumpingo.com/local-policies/yumpingo-cookie-policy-espanol.html',
  },
  terms: {
    en: 'https://static.yumpingo.com/yumpingo-end-user-notice.html',
    'en-UK': 'https://static.yumpingo.com/yumpingo-end-user-notice.html',
    'en-US': 'https://static.yumpingo.com/yumpingo-end-user-notice.html',
    'ar-AE': 'https://static.yumpingo.com/local-policies/yumpingo-end-user-notification-arabic.html',
    'es-PR': 'https://static.yumpingo.com/local-policies/yumpingo-end-user-notification-espanol.html',
  },
});

/**
 * Retrieve the current privacy policy link, based on the provided currentLang
 */
export const getPrivacyPolicyLink = (currentLang: string) => {
  return policyDocs.privacy[currentLang as 'en'] || policyDocs.privacy['en-UK'];
};

/**
 * Retrieve the current cookie policy link, based on the provided currentLang
 */
export const getCookiePolicyLink = (currentLang: string) => {
  return policyDocs.cookies[currentLang as 'en'] || policyDocs.cookies['en-UK'];
};

/**
 * Retrieve the current end user notice policy link, based on the provided currentLang
 */
export const getEndUserNoticeLink = (currentLang: string) => {
  return policyDocs.terms[currentLang as 'en'] || policyDocs.terms['en-UK'];
};

// add postfix or prefix to string based on current language if it's left to right or right to left
export const addPostfixToString = (str: string, postfix: string, i18n: typeof I18n) => {
  return i18n.dir() === 'ltr' ? `${str}${postfix}` : `${postfix}${str}`;
};

import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import { getVisitorInfo, VisitorInfo } from '../../pages/review/services/session';
import { createInitialAsyncState } from '../utils';

export const fetchVisitorInfo = createAsyncThunk('fingerprint', async (_, thunkAPI) => {
  // getVisitorInfo always return a valid object as it's not an api call so there's no need to wrap into a try catch
  const response = await getVisitorInfo();
  return response as VisitorInfo;
});

const fingerprintSlice = createSlice({
  name: 'fingerprintVisitorInfo',
  initialState: createInitialAsyncState<VisitorInfo | null>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVisitorInfo.pending, (state) => {
        state.isFetching = true;
        state.didInvalidate = false;
        state.error = undefined;
        state.data = null;
      })
      .addCase(fetchVisitorInfo.fulfilled, (state, action) => {
        state.isFetching = false;
        state.didInvalidate = false;
        state.error = undefined;
        state.data = action.payload;
      })
      .addCase(fetchVisitorInfo.rejected, (state, action) => {
        // The promise will always be fulfilled so we don't need to handle the error.
        // The following in code is for keeping the same pattern as other slices
        state.data = undefined;
        state.isFetching = false;
        state.didInvalidate = true;
        state.error = action.payload as SerializedError;
      });
  },
});

export default fingerprintSlice.reducer;

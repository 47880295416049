import { palette } from '@yumpingo/yummy-components/theme/Palette';
import { stringEqual } from '@yumpingo/yummy-components/utils/stringEqual';
import isEqual from 'lodash/isEqual';
import { Answer } from '../../../models/Answer';
import { ItemReview } from '../../../models/ItemReview';
import { QuestionResponse } from '../../../models/QuestionResponse';
import { ReviewQuestion } from '../../../models/ReviewQuestion';
import { Session } from '../../../models/Session';
import { submitSession } from '../services/session';
import { retrieveToken } from '../../../utils/token-utils';
import i18n from '../../../i18n';
import { Flow } from '../../../models/Flow';
import { useDispatch, useSelector } from 'react-redux';
import { selectReviewConfigFlow, selectReviewConfigService } from '../../../redux/reviewConfig/reviewConfigSelectors';
import { MultipleChoiceResponse } from '@yumpingo/yummy-components';
import { selectTokenState } from '../../../redux/token/tokenSelectors';
import { useGuestJourney } from '../../../utils/guest-journey';
import { updateSession } from '../../../redux/sessionState/sessionSlice';
import { selectSessionState } from '../../../redux/sessionState/sessionSelectors';
import { selectFingerprintData } from '../../../redux/fingerprint/fingerprintStateSelectors';
import { selectSessionExperienceDriversSelector } from '../../../redux/experienceDrivers/experienceDriversStateSelectors';
import { setShowMissingItemsModal } from '../../../redux/missingItems/missingItemsStateSlice';
import {
  beginSessionAction,
  removeReviewAction,
  removeAllMissingReviewsAction,
  addReviewAction,
  filterOutReviewsAction,
  addAnswerAction,
  removeAnswerAction,
} from '../../../redux/middleware/actions';

import {
  BeginSessionActionPayload,
  RemoveReviewActionPayload,
  RemoveAllMissingReviewsActionPayload,
  AddReviewActionPayload,
  FilterOutReviewsActionPayload,
  AddAnswerActionPayload,
  RemoveAnswerActionPayload,
} from '../../../redux/middleware/models';

const prepareSessionForApi = <T extends { topic: string; other?: string; ids: string[] }[]>(
  session: Session,
  questions: ReviewQuestion[],
  experienceDrivers: T,
  flow: Flow
): Session => {
  const extra = {
    ...session?.extra,
    experienceDrivers: experienceDrivers.length ? experienceDrivers : undefined,
  };

  const questionMetadata = questions.map((question) => ({
    id: question.id,
    followUpQuestions:
      question.followUpQuestions?.map((followUp) => ({
        id: followUp.id,
        conditions: followUp.followUpConditions?.values || [],
      })) || [],
  }));
  const metadata = { ...session?.metadata, questionMetadata };

  const updatedSessionObj = { ...session, metadata };

  const missingDishes = session?.reviews.filter((r) => !!r.missing) || [];
  if (missingDishes.length) {
    const id = flow === 'standard' ? 'dishId' : 'posDishId';
    extra.missingDishes = {
      [`${id}s`]: missingDishes?.filter((d) => d[id] !== undefined).map((d) => d[id]),
    };

    updatedSessionObj.reviews = updatedSessionObj.reviews!.filter((r) => !r.missing);
  }

  const hasParamsDefined = !!Object.values(extra).find((value) => value !== undefined);

  return { ...(updatedSessionObj as Session)!, extra: hasParamsDefined ? extra : undefined };
};

interface SessionManager {
  begin: () => void;
  sendToApi: (publish: boolean) => Promise<void>;
  review: (review: ItemReview | ItemReview[]) => void;
  removeReview: (review: ItemReview) => void;
  removeAllMissingReviews: () => void;
  filterOutReviews: (reviews: ItemReview[]) => void;
  answer: (question: ReviewQuestion, answer: QuestionResponse, answerIdToRemove?: string) => void;
}

export function useUpdateSession(): SessionManager {
  const session = useSelector(selectSessionState);
  const dispatch = useDispatch();
  const experienceDrivers = useSelector(selectSessionExperienceDriversSelector);
  const flow = useSelector(selectReviewConfigFlow);
  const serviceConfig = useSelector(selectReviewConfigService);
  const apiToken = useSelector(selectTokenState) || retrieveToken();
  const gj = useGuestJourney();

  const expDriversEnabled = !!serviceConfig.experienceDrivers?.length;

  const fingerprintVisitorInfoConfig = useSelector(selectFingerprintData);

  const billIdFromUrl = new URL(window.location.href).searchParams.get('billId');
  const urlParts = window.location.href.split('/');
  const billIdIndex = urlParts.indexOf('bill');

  // If 'billIdFromUrl' is undefined, we need to verify whether the URL contains a 'billId' parameter,
  //  and if so, extract the 'billId' from the URL path '/bill/:billId
  const billId = !billIdFromUrl && billIdIndex !== -1 ? urlParts[billIdIndex + 1] : billIdFromUrl;

  return {
    begin: () => {
      const payload: BeginSessionActionPayload = {
        expDriversEnabled,
        foodFirst: !serviceConfig.showFirst,
        questionIds: serviceConfig.questions.map((q) => q.id),
        language: i18n.resolvedLanguage,
      };
      dispatch(beginSessionAction(payload));
      dispatch(
        updateSession({
          createdAt: new Date().toISOString(),
          reviews: [],
          answers: [],
          metadata: {
            expDriversEnabled,
            foodFirst: !serviceConfig.showFirst,
            questionIds: [],
            language: i18n.resolvedLanguage,
          },
          billId: billId || undefined,
        })
      );
    },
    sendToApi: async (publish: boolean) => {
      if (session?.status === 'published') {
        return;
      }

      const updatedSession = await submitSession(
        prepareSessionForApi(session!, serviceConfig.questions, experienceDrivers, flow),
        publish,
        apiToken!,
        fingerprintVisitorInfoConfig!
      );
      dispatch(updateSession({ ...session!, ...updatedSession }));
      dispatch(setShowMissingItemsModal(false));
    },
    // removing a single review from session
    removeReview: (review: ItemReview) => {
      const payload: RemoveReviewActionPayload = {
        dishId: review.dishId,
        posDishId: review.posDishId,
      };
      dispatch(removeReviewAction(payload));
      dispatch(
        updateSession({
          ...session!,
          reviews: session!.reviews.filter((rev) => rev.dishId !== review.dishId || rev.posDishId !== review.posDishId),
        })
      );
    },
    // remove all reviews with missing items
    removeAllMissingReviews: () => {
      const missingReviews = session!.reviews.filter((review) => review.missing);
      const payload: RemoveAllMissingReviewsActionPayload[] = missingReviews.map((review) => ({
        dishId: review.dishId,
        posDishId: review.posDishId,
      }));
      dispatch(removeAllMissingReviewsAction(payload[0]));
      dispatch(updateSession({ ...session!, reviews: session!.reviews.filter((review) => !review.missing) }));
    },
    // adding new reviews to the session, as a single object or as an array
    review: (review: ItemReview | ItemReview[]) => {
      const reviews = Array.isArray(review) ? review : [review];
      const payload: AddReviewActionPayload[] = reviews.map((review) =>
        review.missing
          ? {
              posDishId: review.posDishId,
              dishId: review.dishId,
              missing: review.missing,
              createdAt: review.createdAt,
            }
          : (review as AddReviewActionPayload)
      );
      dispatch(addReviewAction(payload[0]));
      dispatch(updateSession({ ...session!, reviews: [...(session!.reviews || []), ...reviews] }));
    },
    // removing multiple reviews at once
    filterOutReviews: (reviews) => {
      const payload: FilterOutReviewsActionPayload[] = reviews.map((review) => ({
        dishId: review.dishId,
        posDishId: review.posDishId,
      }));
      dispatch(filterOutReviewsAction(payload[0]));
      dispatch(
        updateSession({
          ...session!,
          reviews: session!.reviews.filter((review) => {
            const isFound = reviews.find((item) => isEqual(review, item));

            return !isFound;
          }),
        })
      );
    },
    answer: (question: ReviewQuestion, answer: QuestionResponse, answerIdToRemove?: string) => {
      const currentAnswer = session?.answers.find((ans) => stringEqual(ans.questionId, question.id));
      const isMultiSelect = question.answerType === 'multiSelect';
      const isMultipleChoice = question.answerType === 'multipleChoice';

      if (currentAnswer) {
        const updatedRecord: Answer = isMultiSelect
          ? { ...currentAnswer, selectedOptions: answer as MultipleChoiceResponse }
          : isMultipleChoice
          ? {
              ...currentAnswer,
              answer: (answer as MultipleChoiceResponse)?.map((a) => a.name)[0],
            }
          : { ...currentAnswer, answer };
        if (isMultiSelect) {
          // DONT SEND THE "ANSWER" PROPERTY IF MULTISELECT
          delete updatedRecord.answer;
        }
        const answers = session!.answers.map((ans) => (ans === currentAnswer ? updatedRecord : ans));
        dispatch(
          updateSession({
            ...session!,
            answers: !!answerIdToRemove ? answers.filter((ans) => ans.questionId !== answerIdToRemove) : answers,
          })
        );
      } else {
        const newRecord: Answer = {
          questionId: question.id,
          createdAt: new Date().toISOString(),
          answer: answer,
          isCore: question.isCore,
        };
        if (isMultiSelect) {
          newRecord.selectedOptions = answer as MultipleChoiceResponse;
          // DONT SEND THE "ANSWER" PROPERTY IF MULTISELECT
          delete newRecord.answer;
        }
        if (isMultipleChoice) {
          // Send only the option name as an answer (answer gets turned to string before )
          newRecord.answer = (answer as MultipleChoiceResponse)?.map((a) => a.name)[0];
        }
        const answers = session!.answers.concat(newRecord);
        dispatch(
          updateSession({
            ...session!,
            answers: !!answerIdToRemove ? answers.filter((ans) => ans.questionId !== answerIdToRemove) : answers,
          })
        );
      }
      const payloadAdd: AddAnswerActionPayload = {
        questionId: question.id,
        answer: answer,
        isCore: question.isCore,
      };

      dispatch(addAnswerAction(payloadAdd));
      // only logs if the answer is being removed and it exists in the session
      if (!!answerIdToRemove && !!session?.answers.find((ans) => ans.questionId === answerIdToRemove)) {
        const payloadRemove: RemoveAnswerActionPayload = {
          questionId: answerIdToRemove,
        };
        dispatch(removeAnswerAction(payloadRemove));
      }
    },
  };
}

import { useEffect, useRef, useState } from 'react';
import { retrieveToken, saveToken } from '../utils/token-utils';
import { customHistory } from '../components/routing/custom-history-router/CustomHistoryRouter';
import { environment } from '../environment';
import { useDispatch } from 'react-redux';
import { updateToken } from '../redux/token/tokenSlice';

/**
 * This hook takes care of reading and storing the API token when provided by the "?token" URL search parameters.
 * It utilizes the {@link saveToken} function to save the token locally, allowing for easy access throughout the application without
 * the need for manual handling (and relative headache 🤕).
 *
 * ** It is intended for use during the initial bootstrap of the application. **
 *
 * Why do we need such a hook?
 * At the moment, the application has two methods for handling the API token:
 * the first is through the URL query parameters, where the token is indeed injected via the "token" query parameter.
 * The second method is through the URL hash, where the token is appended to the URL after the "#" symbol.
 *
 * This hook specifically focuses on the first method.
 *
 * It returns a boolean value indicating whether the token has been successfully saved or not (the `ready` state)
 * This value is only relevant when the token is provided via the URL query parameters, otherwise, it returns true by default.
 */
const useTokenParameterSetup = () => {
  const paramsRef = useRef(new URLSearchParams(window.location.search));
  const [ready, setReady] = useState(!paramsRef.current.has('token'));
  const dispatch = useDispatch();

  useEffect(() => {
    if (paramsRef.current.has('token')) {
      const rawToken = paramsRef.current.get('token') as string;
      // have this in a try catch
      const token = atob(decodeURIComponent(rawToken));
      const tokenIsValid = saveToken(token);

      dispatch(updateToken(token));
      setReady(true);

      if (tokenIsValid && environment.name === 'production') {
        paramsRef.current.delete('token');

        customHistory.replace({
          pathname: customHistory.location.pathname,
          search: paramsRef.current.toString(),
        });
      }
      return;
    }

    const token = retrieveToken();

    if (token) {
      dispatch(updateToken(token));
      setReady(true);
    }
  }, [dispatch]);

  return ready;
};

export default useTokenParameterSetup;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // temporarily disabling the language detector until product decides how we want to handle this
  // problem arises when browser language is set without a region code, so backend ignores the language in the accept-language request-header
  // but the language detector still picks up the language from the browser and sets it as the language - static and dynamic text will be in different languages
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    load: 'currentOnly',
    // fallbackLng: 'en' means that if a translation is missing in the current language,
    // i18next will try to find the translation in the English language files.
    fallbackLng: 'en',
    ns: ['translation'], // specify namespaces
    defaultNS: 'translation', // specify default namespace
    backend: {
      loadPath: `${!!process.env.BASE_URL ? process.env.BASE_URL : ''}/locales/{{lng}}/{{ns}}.json`,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;

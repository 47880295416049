import { createSlice } from '@reduxjs/toolkit';
import { ItemReview } from '../../models/ItemReview';
import { THREE_HOURS } from '../utils';
import { CurrentItemReviewWithExpiry } from './currentItemReviewStateSelectors';

export const CURRENT_ITEM_REVIEW_STORAGE_KEY = btoa('Review/Items/Current');

const currentItemReviewSlice = createSlice({
  name: 'currentItemReview',
  initialState: null as ItemReview | null,
  reducers: {
    updateCurrentItemReview: (state, action) => {
      if (!action.payload) {
        localStorage.removeItem(CURRENT_ITEM_REVIEW_STORAGE_KEY);
        return null;
      }
      const expirationTime = Date.now() + THREE_HOURS;
      const currentItemReviewWithExpiry: CurrentItemReviewWithExpiry = {
        data: action.payload,
        expiresAt: expirationTime,
      };
      localStorage.setItem(CURRENT_ITEM_REVIEW_STORAGE_KEY, JSON.stringify(currentItemReviewWithExpiry));
      return { ...state, ...action.payload };
    },
  },
});

export const { updateCurrentItemReview } = currentItemReviewSlice.actions;

export default currentItemReviewSlice.reducer;

import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Unsubscribe } from '../../../../../pages/unsubscribe/Unsubscribe';

const UnsubscribeRoutes: FC = () => (
  <Switch>
    <Route path="/unsubscribe" exact>
      <Unsubscribe />
    </Route>
    {/* Backwards compatability with old unsubscribe links - can be removed after a time */}
    <Route
      path="/unsubscribe/:token"
      exact
      render={(props) => <Redirect to={`/entry#token=${props.match.params.token}`} />}
    />
  </Switch>
);

export default React.memo(UnsubscribeRoutes);

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import LoadingOverlay from '@yumpingo/yummy-components/components/molecules/LoadingOverlay';
import { setModalAppElement } from '@yumpingo/yummy-components/components/atoms/Modal/helpers';
import { App } from './App';

import * as serviceWorker from './serviceWorker';

import './index.scss';

const rootElementId = 'root';
setModalAppElement(rootElementId);

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingOverlay color="grey4" />}>
      <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById(rootElementId)
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { createSlice } from '@reduxjs/toolkit';
import { THREE_HOURS } from '../utils';
import { TopicsWithOtherComments, ExpDriverTopic } from '../../models/ServiceConfig';
import { ExperienceDriversWithExpiry } from './experienceDriversStateSelectors';

export interface ExperienceDriversState {
  topicIssues: ExpDriverTopic[];
  topicOtherComments: TopicsWithOtherComments[];
}

export const EXPERIENCE_DRIVERS_STORAGE_KEY = btoa('Review/ExperienceDrivers/Current');

const experienceDriversSlice = createSlice({
  name: 'experienceDrivers',
  initialState: null as ExperienceDriversState | null,
  reducers: {
    updateTopicsWithIssues: (state, action: { payload: ExpDriverTopic[] }) => {
      const expirationTime = Date.now() + THREE_HOURS;
      const experienceDriversWithExpiry: ExperienceDriversWithExpiry = {
        data: { topicIssues: action.payload, topicOtherComments: state?.topicOtherComments || [] },
        expiresAt: expirationTime,
      };
      localStorage.setItem(EXPERIENCE_DRIVERS_STORAGE_KEY, JSON.stringify(experienceDriversWithExpiry));
      return { topicIssues: action.payload, topicOtherComments: state?.topicOtherComments || [] };
    },
    updateTopicsWithComments: (state, action: { payload: TopicsWithOtherComments[] }) => {
      const expirationTime = Date.now() + THREE_HOURS;
      const experienceDriversWithExpiry: ExperienceDriversWithExpiry = {
        data: { topicIssues: state?.topicIssues || [], topicOtherComments: action.payload },
        expiresAt: expirationTime,
      };
      localStorage.setItem(EXPERIENCE_DRIVERS_STORAGE_KEY, JSON.stringify(experienceDriversWithExpiry));
      return { topicIssues: state?.topicIssues || [], topicOtherComments: action.payload };
    },
    resetExperienceDrivers: (state) => {
      localStorage.removeItem(EXPERIENCE_DRIVERS_STORAGE_KEY);
      return null;
    },
  },
});

export const { updateTopicsWithIssues, updateTopicsWithComments, resetExperienceDrivers } =
  experienceDriversSlice.actions;

export default experienceDriversSlice.reducer;

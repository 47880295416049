import { FC, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const ScrollToTop: FC<RouteComponentProps> = ({ history }) => {
  useEffect(() => {
    let pathname = history.location.pathname;
    return history.listen((location) => {
      // Make sure we don't scroll on hash change
      // Otherwise sliders/modals could trigger a scroll to top
      if (location.pathname !== pathname) {
        window.scrollTo(0, 0);
        pathname = location.pathname;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

export default withRouter(ScrollToTop);

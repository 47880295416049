import Input from '@yumpingo/yummy-components/components/molecules/Input';
import i18next, { t } from 'i18next';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useValidateDate } from '../hooks/use-validate-date';
import useSimpleToaster, { createToast } from '@yumpingo/yummy-components/hooks/useSimpleToaster';
import { selectReviewConfigCurrencyAsDateFormat } from '../../../redux/reviewConfig/reviewConfigSelectors';
import { useSelector } from 'react-redux';
import './DateOfBirthInput.style.scss';

export type DobInputName = 'dayDob' | 'monthDob' | 'yearDob';
type DobInputObject = Record<
  'day' | 'month' | 'year',
  { name: DobInputName; order: number; placeholder: string; maxLength: number }
>;
const DATE_OF_BIRTH_INPUTS: DobInputObject = {
  day: { order: 1, name: 'dayDob', placeholder: t('DD'), maxLength: 2 },
  month: { order: 2, name: 'monthDob', placeholder: t('MM'), maxLength: 2 },
  year: { order: 3, name: 'yearDob', placeholder: t('YYYY'), maxLength: 4 },
};

interface Props {
  inputValues: { dayDob?: string; monthDob?: string; yearDob?: string };
  onChange: (event: React.ChangeEvent<HTMLInputElement>, name: DobInputName) => void;
}

export const DateOfBirthInput: FC<Props> = ({ inputValues, onChange }) => {
  // use correct format (DD/MM/YYYY vs. MM/DD/YYY) for inputs and error label:
  const { dateFormat } = useSelector(selectReviewConfigCurrencyAsDateFormat); // defaults to GBP
  const { dobInputs, errorLabel } = useMemo(() => {
    if (dateFormat === 'USD') {
      DATE_OF_BIRTH_INPUTS.day.order = 2;
      DATE_OF_BIRTH_INPUTS.month.order = 1;
    } else {
      DATE_OF_BIRTH_INPUTS.day.order = 1;
      DATE_OF_BIRTH_INPUTS.month.order = 2;
    }
    const dobInputs = Object.values(DATE_OF_BIRTH_INPUTS).sort((a, b) => a.order - b.order);
    // ensure error label depends on page direction:
    const isLTR = i18next.dir() === 'ltr';
    const errorLabelFormat = isLTR ? dobInputs : [...dobInputs].reverse();
    const errorLabel = t('invalidDate') + ': ' + errorLabelFormat.map((dobInput) => dobInput.placeholder).join(' / ');
    return { dobInputs, errorLabel };
  }, [dateFormat]);

  // show error toast when invalid date:
  const { invalidDate } = useValidateDate(inputValues.dayDob, inputValues.monthDob, inputValues.yearDob);
  const { addToast } = useSimpleToaster();
  useEffect(() => {
    if (invalidDate && inputValues.dayDob && inputValues.monthDob && inputValues.yearDob) {
      addToast(createToast(errorLabel, 'error', undefined, 3000)); // 3 seconds
    }
  }, [invalidDate, errorLabel, addToast, inputValues.dayDob, inputValues.monthDob, inputValues.yearDob]);

  // prevent non-numeric characters and limit length of input:
  const checkKeyValidity = useCallback((event, maxLength) => {
    if (!/[0-9]/.test(event.key) || event.currentTarget.value.length >= maxLength) {
      event.preventDefault();
    }
  }, []);

  return (
    <div>
      <p className="date-of-birth-superscript">{t('enterBirthdayForSpecialRewards')}</p>
      <div className="date-of-birth-container" data-cy="dateOfBirth-container">
        {dobInputs.map((dobInput) => (
          <Input
            key={dobInput.name}
            name={dobInput.name}
            value={inputValues[dobInput.name]}
            onChange={(event) => onChange(event, dobInput.name)}
            error={invalidDate}
            type="number"
            placeholder={dobInput.placeholder}
            onKeyPress={(event) => checkKeyValidity(event, dobInput.maxLength)}
            className="dob-input"
            data-cy={`${dobInput.name}`}
            uncontrolled
          />
        ))}
      </div>
    </div>
  );
};

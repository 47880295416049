import { ComponentType, lazy } from 'react';

type ComponentImportType<P = {}> = () => Promise<{ default: ComponentType<P> }>;

const sessionKey = 'lazyWithRetry';

const lazyWithRetry = <P extends {}>(componentImport: ComponentImportType<P>, componentName: string) => {
  return lazy(async () => {
    const retryKey = `${sessionKey}-${componentName}`;
    const hasRefreshed = window.sessionStorage.getItem(retryKey) || 'false';

    try {
      window.sessionStorage.setItem(retryKey, 'false');
      return await componentImport();
    } catch (error) {
      if (hasRefreshed === 'false') {
        window.sessionStorage.setItem(retryKey, 'true');
        window.location.reload();
      }

      if (hasRefreshed === 'true') {
        const newError = new Error('chunkLoadError');
        newError.cause = error;
        throw new Error('chunkLoadError');
      }
    }
    return await componentImport();
  });
};

export default lazyWithRetry;

export const REVIEW_ROUTES = {
  review: '/review',
  splash: '/review/splash',
  food: '/review/food',
  service: '/review/service/:questionId',
  subscribe: '/review/subscribe',
  punchhSubscribe: '/review/punchh-subscribe',
  complaint: '/review/complaint',
  final: '/review/thank-you',
  sending: '/review/sending',
};

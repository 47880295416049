import { FilteredMenuItem } from './FilteredMenuItem';
import { StandardMenuItem } from './StandardMenuItem';
import { Flow } from './Flow';

export type MenuItem = StandardMenuItem | FilteredMenuItem;

export interface FoodConfigCommon<T extends MenuItem> {
  allowSkip?: boolean;
  increaseReviews?: boolean;
  enableMissingDishes: boolean;
  title?: string;
  subtitle?: string;
  // the purpose of this additional union is to trick typescript into allowing
  // array functions to be called, e.g. menu.filter, otherwise you hit this issue:
  // https://github.com/microsoft/TypeScript/issues/33591
  menu: T[] & Array<MenuItem>;
}

export type StandardConfig = FoodConfigCommon<StandardMenuItem>;

export type FilteredConfig = FoodConfigCommon<FilteredMenuItem>;

export type FoodConfig = StandardConfig | FilteredConfig;

export function getMenuItemId(flow: Flow, item: MenuItem): string {
  return isStandardMenuItem(flow, item) ? item.dishId : item.posDishId;
}

export function isStandardMenuItem(flow: Flow, item: MenuItem): item is StandardMenuItem {
  return flow === 'standard';
}

export function getMenuItemDisplayName(item: MenuItem): string {
  return item.shortName || item.name;
}

import { useState } from 'react';

export function usePreventMultipleClicks(): (
  onClick: () => void
) => (e: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void {
  const [disabled, setDisabled] = useState(false);
  return (onClick) => (e) => {
    e!.currentTarget.setAttribute('disabled', 'true');
    if (!disabled) {
      onClick();
      setDisabled(true);
    }
  };
}

import { environment } from '../environment';
import { ReviewConfig } from '../models/ReviewConfig';
import { createCachableService } from '../utils/api-request';
import { getBillId } from '../utils/digi-bill';

export const getReviewConfig = createCachableService((apiGET, apiToken: string, lang = 'en') => {
  const headers = {
    'Accept-Language': lang,
  };
  const url = new URL(`${environment.guestApiUrl}/config/review`);
  const billId = getBillId();

  if (billId) {
    url.searchParams.set('billId', billId);
  }

  return apiGET<ReviewConfig>(url.toString(), apiToken, undefined, headers);
});

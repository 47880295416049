import { createAsyncThunk, createSlice, SerializedError } from '@reduxjs/toolkit';
import i18n from '../../i18n';
import { getBrandQrCodeConfig } from '../../services/brand';
import { createInitialAsyncState } from '../utils';
import { BrandQrCodeResponse } from '../../models/BrandQrCodeResponse';
import { ApiError } from '@yumpingo/yummy-components';

export const fetchBrandConfig = createAsyncThunk(
  'brandConfig/fetchBrandConfig',
  async (brandName: string, thunkAPI) => {
    try {
      const response = await getBrandQrCodeConfig(brandName, i18n.resolvedLanguage);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue((e as ApiError).json);
    }
  }
);

const brandConfig = createSlice({
  name: 'brandConfig',
  initialState: createInitialAsyncState<BrandQrCodeResponse>(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrandConfig.pending, (state) => {
        state.isFetching = true;
        state.didInvalidate = false;
        state.error = undefined;
        state.data = undefined;
      })
      .addCase(fetchBrandConfig.fulfilled, (state, action) => {
        state.isFetching = false;
        state.didInvalidate = false;
        state.error = undefined;
        state.data = action.payload;
      })
      .addCase(fetchBrandConfig.rejected, (state, action) => {
        state.data = undefined;
        state.isFetching = false;
        state.didInvalidate = true;
        state.error = action.payload as SerializedError;
      });
  },
});

export default brandConfig.reducer;

import { Global } from '@emotion/react';
import React, { FC } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { LoadingOverlay } from '@yumpingo/yummy-components';
import { AppRoutes } from './components/routing/app-routes/AppRoutes';
import { CustomHistoryRouter } from './components/routing/custom-history-router/CustomHistoryRouter';
import ScrollToTop from './components/scroll-to-top/ScrollToTop';
import { GoogleAnalytics } from './components/startup/google-analytics/GoogleAnalytics';
import { environment } from './environment';
import useBoostrapProcess from './hooks/useBoostrapProcess';
import { GuestJourney } from './components/startup/guest-journey/GuestJourney';
// importing polyfill for the animate API for older browsers
import 'web-animations-js';
import './i18n';
import { SimpleToastProvider } from '@yumpingo/yummy-components/components/organisms/SimpleToaster/SimpleToastContext';
import SimpleToaster from '@yumpingo/yummy-components/components/organisms/SimpleToaster';
import Styles from './theme/Styles';
import { CustomThemeProvider } from './theme/theme';
import SessionTimeout from './components/session-timeout/SessionTimeout';

// needs refactoring or extracting to yummy components
const FooterMarginHelper: FC = () => (
  <Global
    styles={`
    :root {
        --footer-margin: 0px;
        --page-padding: 16px;
        @media (min-width: 360px) {
          --page-padding: 24px;
        }
        @media (min-width: 480px) {
          --page-padding: 32px;
        }
    }`}
  />
);

// This was split into two components so that the redux provider could
// encapsulate the whole application, specifically the isReady/bootstrap process
export const App: FC = () => {
  return (
    <Provider store={store}>
      <CustomThemeProvider>
        <Styles>
          <AppContent />
        </Styles>
      </CustomThemeProvider>
    </Provider>
  );
};

const AppContent: FC = () => {
  const isReady = useBoostrapProcess();

  return (
    <>
      {!isReady && <LoadingOverlay color="grey4" />}
      {isReady && (
        <SimpleToastProvider>
          <CustomHistoryRouter>
            <GoogleAnalytics trackingCode={environment.analyticsTrackingCode} />
            <GuestJourney />
            <ScrollToTop />
            <SessionTimeout>
              <AppRoutes />
            </SessionTimeout>
          </CustomHistoryRouter>
          <SimpleToaster position="top" padding="96px 16px 0 16px !important" />
        </SimpleToastProvider>
      )}
      <FooterMarginHelper />
    </>
  );
};

import { createSlice } from '@reduxjs/toolkit';

interface GlobalState {
  loadedFromStorage: boolean;
  error: string | null;
}

const initialState: GlobalState = {
  loadedFromStorage: false,
  error: null,
};

const globalSlice = createSlice({
  name: 'global',
  initialState: initialState,
  reducers: {
    updateLoadedFromStorage: (state, action) => {
      state.loadedFromStorage = action.payload;
    },
    updateError: (state, action) => {
      state.error = action.payload;
    },
  },
});
//
export const { updateLoadedFromStorage, updateError } = globalSlice.actions;

export default globalSlice.reducer;
